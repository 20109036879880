import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {selectEventForCart} from '../actions/index'
import Rating from '../containers/rating';
import {sprintf} from 'sprintf-js';
import {Helmet} from "react-helmet";
import { FacebookProvider, Feed } from 'react-facebook';

require('dotenv').config()


class EventPage extends React.Component {
    renderBuyButton(event_id, price, tickets_available) {
        if (tickets_available > 0) {
            return (
                <a href={"/" + this.props.match.params.lang + "/cart/" + event_id}><button className="button buy">{ this.props.translate('event_details_buy_at') } {price}</button></a>
            );
        } else {
            return (
                <button className="button buy">{ this.props.translate('event_details_sold_out') }</button>
            );
        }
    }
    makeEventLink(event) {
        var title_link = event.event_name;
        title_link = title_link.replace(/ /g, "-");
        title_link = title_link.replace(/[^a-zA-Z0-9-]*/gi, "");
        //var link = process.env.REACT_APP_RETURN_URL + '/' + this.props.match.params.lang + '/event/' + event.event_id + "/" + title_link;
        var link = 'https://www.paintfest.com' + '/' + this.props.match.params.lang + '/event/' + event.event_id + "/" + title_link;
        return link;
    }
    componentDidMount() {
        this.props.selectEventForCart(this.props.match.params.event_id);
    }

    render() {
        var event = this.props.event;

        if (event == null) {
            return (
                <div className="col-md-3 tile"/>
            );
        }

        var tmpStyle = { background: "url( 'https://storage.googleapis.com/paintfest/" + event.artist_thumbnail + "')" };
        var eventPrice = (typeof(event) === undefined) ? '' : event.event_currency + " " + sprintf("%0.2f", event.event_price);

        return (
            <div className="single">
            <Helmet>
                <title>Come and paint {event.event_name} - PaintFest</title>
                <meta name="description" content="PaintFest is the only night out that will result in you going home with a unique piece of art: <strong>{event.event_name}</strong> will be your masterpiece." />
                <meta property="fb:app_id" content="111022139701836" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={event.event_name} />
                <meta property="og:description" content={event.event_details } />
                <meta property="og:url" content={ 'https://www.paintfest.com' + "/" + this.props.match.params.lang + "/event/" + event.event_id } />
                <meta property="og:image" content={ "https://storage.googleapis.com/paintfest/" + event.painting_image} />
                <meta property="og:site_name" content="PaintFest" />
            </Helmet>
            <FacebookProvider appId="111022139701836">
              <section className="main main-title">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                        <h1>Come and paint {event.event_name}!</h1>
                        <p className="intro">{ this.props.translate('event_page_intro') }</p>
                     </div>
                  </div>
                </div>
              </section>
                <section className="event-single">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 left">
                        <div className="painting">
                          <img alt="" src={"https://storage.googleapis.com/paintfest/" + event.painting_image} />
                        </div>
                        <div className="about">
                          <p dangerouslySetInnerHTML={{__html: event.event_description }} />
                        </div>
                        <div className="artist col-md-12">
                          <div className="avatar" style={tmpStyle} ></div>
                          <h4 className="name">{event.artist_displayname}</h4>
                          <Rating avg={event.artist_rating_avg} count={event.artist_rating_count}/>
                          <p>{ event.artist_bio }</p>
                        </div>
                        <div className="venue">
                          <h4 className="name">{ event.venue_name }</h4>
                          <Rating avg={event.venue_rating_avg} count={event.venue_rating_count}/>
                          <p dangerouslySetInnerHTML={{__html: event.venue_description }} />
                        </div>
                      </div>
                      <div className="col-md-4 right">
                        <div className="details">
                          <p className="text-label">{ this.props.translate('event_details_when') }</p>
                          <p className="location">{event.event_startdatetime}</p>
                          <p className="text-label">{ this.props.translate('event_details_where') }</p>
                          <p className="location"> {this.props.event.venue_address} {this.props.event.venue_city}</p>
                        </div>

                        <div className="buy">
                          <p className="text-label">{event.event_tickets_available} { this.props.translate('event_details_tickets_left') } </p>
                          {this.renderBuyButton(event.event_id, eventPrice, event.event_tickets_available)}
                        </div>
                      </div>
                    </div>
                    <div className="row map">
                      <iframe width="100%" height="400" title="map" src={ "https://www.google.com/maps/embed/v1/search?key=AIzaSyAq8i5OupH9jXoXsMJKdK4uyNSGxZ73qNs&q=" + encodeURIComponent(this.props.event.venue_name + "," + this.props.event.venue_address + ",Amsterdam,NL")} allowFullScreen></iframe>
                    </div>
                  </div>
                </section>
              </FacebookProvider>
            </div>
        );
    }
}


/*
                        <div className="social-share col-md-12">
                          <Feed link={ this.makeEventLink(event) }
                                source={"https://storage.googleapis.com/paintfest/" + event.painting_image} >
                            <button className="facebook" type="button"><i className="fa fa-facebook"></i>&nbsp;{ this.props.translate('share_on_facebook') }</button>
                          </Feed>
                         </div>
                         # boven Buy knop

*/
function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
       event:               state.event_for_cart,

    };
}

function matchDispatchToProps(dispatch) {
    return {
        selectEventForCart:   (event_id) => dispatch(selectEventForCart(event_id))
    };

};

export default connect(mapStateToProps, matchDispatchToProps)(EventPage);
