import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class AboutUs extends React.Component {
    render() {
        /* make shortcut */
        var tr = this.props.translate;

        return (
          <div className="page about-us">
          <section className="main main-title hero-image">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1>{ tr('header_about_us') }</h1>
                    <h2>{ tr('about_us_team') }</h2>
                  </div>
                </div>
              </div>
            </section>

            <section className="main-content columns">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center text-block">
                    <h2>{ tr('about_us_great_success') }</h2>
                  </div>
                  <div className="col-md-4 text-block text-center usps">
                    <img alt="" src="/images/icon-restaurant.png"/>
                    <h4>{ tr('about_europe_next') }</h4>
                    <p>{ tr('about_us_we_throw') }</p>
                  </div>
                  <div className="col-md-4 text-block text-center usps">
                    <img alt="" src="/images/icon-winner.png"/>
                    <h4>{ tr('about_unique_model') }</h4>
                    <p>{ tr('about_us_we_license') }</p>
                  </div>
                  <div className="col-md-4 text-block text-center usps">
                    <img alt="" src="/images/icon-network.png"/>
                    <h4>{ tr('about_robust') }</h4>
                    <p>{ tr('about_us_we_offer') }</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="main-content map-europe">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-block">
                    <h3>{ tr('about_us_we_introduced') }</h3>
                    <p><strong>{ tr('about_us_europe_1') }</strong></p>
                    <p>{ tr('about_us_europe_2') }</p>
                    <p>{ tr('about_us_europe_3') }</p>
                    <p>{ tr('about_us_europe_4') }</p>
                  </div>
                </div>
              </div>
              <div className="half-image"></div>
            </section>

            <section className="main-content join-our-team">
              <div className="half-image"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-md-offset-6 text-block">
                    <h3>{ tr('about_us_join_team') }</h3>
                    <p>{ tr('about_us_join_team_par1') }</p>
                    <p>{ tr('about_us_join_team_par2') }</p>
                    <p>{ tr('about_us_join_team_par3') }</p>
                    <p>{ tr('about_us_join_team_par4') }</p>
                    <a class="button" href="mailto:ezels@paintfest.com" title="{ tr('about_us_join_team_btn') }">{ tr('about_us_join_team_btn') }</a>
                  </div>
                </div>
              </div>
            </section>

          </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(AboutUs);
