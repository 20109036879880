import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import {submitForm} from '../actions/submit-form';

class RescheduleMyBooking extends React.Component {

    constructor(props) {
        super();
        this.state = {
            "name":     '',
            "email":    '',
            "phone":    '',
            "refcode":  '',
            "message":  '',
        };
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }


    submitForm() {
        // check if all fields are filled
        if (this.state.name === '' ||
            this.state.email === '' ||
            this.state.phone === '' ||
            this.state.refcode === '' ||
            this.state.message === '' ) {

            return;
        }

        this.props.submitForm(
                'reschedule',
                this.state.name,
                this.state.email,
                this.state.phone,
                this.state.message,
                this.state.refcode
        );
    }

    render() {
        /* make shortcut */
        var tr = this.props.translate;

        return (
           <div className="reschedule page">
           <section className="main main-title">
               <div className="container">
                 <div className="row">
                   <div className="col-md-6 col-md-offset-3 text-center">
                     <h1>{ tr('reschedule_title') }</h1>
                     <p className="intro">{ tr('reschedule_par') }</p>
                   </div>
                 </div>
               </div>
             </section>

              <section className="main-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                      <form className="contact-form" action="/action_page.php">
                        <label for="name">{ tr('form_name') }</label>
                        <input type="text" id="name" name="name" placeholder={ tr('form_name') } onChange={(e) => this.handleChange(e) } /><br />

                        <label for="email">{ tr('form_email') }</label>
                        <input type="text" id="email" name="email" placeholder={ tr('form_email') } onChange={(e) => this.handleChange(e) } /><br />

                        <label for="phone">{ tr('form_phone') }</label>
                        <input type="text" id="phone" name="phone" placeholder={ tr('form_phone') } onChange={(e) => this.handleChange(e) } /><br />

                        <label for="refcode">{ tr('form_refcode') }</label>
                        <input type="text" id="refcode" name="refcode" placeholder={ tr('form_refcode') } onChange={(e) => this.handleChange(e) } /><br />

                        <label for="question">{ tr('form_question') }</label>
                        <textarea id="question" name="message" placeholder={ tr('form_question_placeholder') } onChange={(e) => this.handleChange(e) } /><br /><br />

                        <input className="button ghost" type="button" value={ tr('form_submit') } onClick={() => this.submitForm() }     />
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        submitForm: (t, n, e, p, m, r) => dispatch(submitForm(t, n, e, p, m, r)),

    };
};

export default connect(mapStateToProps, matchDispatchToProps)(RescheduleMyBooking);
