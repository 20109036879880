import React from 'react';
import EventList from '../containers/event-list';
import EventDetails from '../containers/event-details';
import Searchbar from '../containers/searchbar';

class App extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        return (
            <div>
                <Searchbar />
                <EventList />
                <EventDetails lang={this.props.match.params.lang}/>
            </div>
        );
    }
}

export default App;
