import React from 'react';
import ReservationReminder from '../containers/reservationreminder';
import PFCookie from '../containers/pfcookie';

//import Beforeunload from 'react-beforeunload';
                    //<p><span style="color:#ec008c; font-size: 150%;">K9H0SKGB9TM9Z67LJQ</span></p>

/* history */
class PaymentCancelled extends React.Component {

    render() {

        /* get status first */
        var reservation_ref = -1;
        var event_id = -1;
        var params = this.props.location.search.split("&");
        for (var i=0; i < params.length; i++) {
            var kv = params[i].split("=");
            if (kv[0] === "referenceID") {
                reservation_ref = kv[1];
            }
            if (kv[0] === "eventID") {
                event_id = kv[1];
            }
        }

        var link = "/nl/cart/" + event_id;

        return (
            <div>
                <PFCookie event_id={this.props.match.params.event_id} />
                <section className="main main-title">
            		<div className="container">
            			<div className="row">
            				<div className="col-md-12 text-center">
            					<h1>Betaling mislukt...</h1>
            				</div>
            			</div>
            		</div>
            	</section>

                <section className="cart-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 text-center">
                                <p>Helaas.. de betaling is niet gelukt. Click <a href={ link }>hier</a> om het nogmaals te proberen.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default PaymentCancelled;
