import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {submitForm} from '../actions/submit-form';

class HostAPaintFestEvent extends React.Component {

    constructor(props) {
        super();
        this.state = {
            "name":     '',
            "email":    '',
            "phone":    '',
            "message":  ''
        };
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    submitForm() {
        // check if all fields are filled
        if (this.state.name === '' ||
            this.state.email === '' ||
            this.state.phone === '' ||
            this.state.message === '' ) {

            return;
        }

        this.props.submitForm(
                'venue',
                this.state.name,
                this.state.email,
                this.state.phone,
                this.state.message,
        );
    }

    render() {
        /* make shortcut */
        var tr = this.props.translate;
        return (
                <div className="host page">
                <section className="main main-title">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8 col-md-offset-2 text-center">
                          <h1>{ tr('host_event') }</h1>
                          <h2 className="handwriting">{ tr('home_welcome_creativity') }</h2>
                          <p className="intro">{ tr('host_intro') }</p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="main-content">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4 col-md-offset-1 text-block">
                          <h2>{ tr('host_header1') }</h2>
                          <p>{ tr('host_paragraph1') }</p>
                        </div>
                        <div className="col-md-5 col-md-offset-1 image-stack">
                          <div className="stack rotate-right">
                            <img alt="" src="/images/placeholder.jpg"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 col-md-offset-1 image-stack">
                          <div className="stack rotate-left">
                            <img alt="" src="/images/placeholder.jpg"/>
                          </div>
                        </div>
                        <div className="col-md-4 col-md-offset-1 text-block">
                          <h2>{ tr('host_header2') }</h2>
                          <p>{ tr('host_paragraph2') }</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-md-offset-1 text-block">
                          <h2>{ tr('host_header3') }</h2>
                          <p>{ tr('host_paragraph3') }</p>
                        </div>
                        <div className="col-md-5 col-md-offset-1 image-stack">
                          <div className="stack rotate-right">
                            <img alt="" src="/images/placeholder.jpg"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                          <h2>{ tr('interested_q') }</h2>
                          <p>{ tr('host_fill_in_form') }</p>
                          <form className="contact-form" action="/action_page.php">
                            <label for="name">{ tr('form_name') }</label>
                            <input type="text" id="name" name="name" placeholder={ tr('form_name') } onChange={(e) => this.handleChange(e) } /><br />
                            <label for="email">{ tr('form_email') }</label>
                            <input type="text" id="email" name="email" placeholder={ tr('form_email') } onChange={(e) => this.handleChange(e) } /><br />
                            <label for="phone">{ tr('form_phone') }</label>
                            <input type="text" id="phone" name="phone" placeholder={ tr('form_phone') } onChange={(e) => this.handleChange(e) } /><br />
                            <label for="question">{ tr('form_question') }</label>
                            <textarea id="question" name="message" placeholder={ tr('form_question_placeholder') } onChange={(e) => this.handleChange(e) } /><br /><br />
                            <input className="button ghost" type="button" value={ tr('form_submit') } onClick={() => this.submitForm() } />
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
            );
        }
}


function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
        submitForm: (t, n, e, p, m, r) => dispatch(submitForm(t, n, e, p, m, r)),
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(HostAPaintFestEvent);
