import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {submitForm} from '../actions/submit-form';

class BecomeAVenue extends React.Component {

    constructor(props) {
        super();
        this.state = {
            "name":     '',
            "email":    '',
            "phone":    '',
            "message":  ''
        };
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    submitForm() {
        // check if all fields are filled
        if (this.state.name === '' ||
            this.state.email === '' ||
            this.state.phone === '' ||
            this.state.message === '' ) {

            return;
        }

        this.props.submitForm(
                'venue',
                this.state.name,
                this.state.email,
                this.state.phone,
                this.state.message,
        );
    }

    render() {
        /* make shortcut */
        var tr = this.props.translate;
        return (
            <div className="page page-venue">
            <section className="main main-title hero-image">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h1>{ tr('become_v_h1') }</h1>
                      <h2>{ tr('become_v_h2') }</h2>
                      <a href={"/" + this.props.match.params.lang + "/apply-venue" } className="button large no-float">{ tr('footer_join_us') }</a>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content columns">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center text-block">
                      <h2>{ tr('become_v_why') }</h2>
                    </div>
                    <div className="col-md-3 text-block text-center usps">
                      <img alt="" src="/images/icon-revenue.png"/>
                        <h4>{ tr('become_v_usp_1') }</h4>
                      <p>{ tr('become_v_usp_1_par') }</p>
                    </div>
                    <div className="col-md-3 text-block text-center usps">
                      <img alt="" src="/images/icon-winner.png"/>
                        <h4>{ tr('become_v_usp_2') }</h4>
                      <p>{ tr('become_v_usp_2_par') }</p><br />
                    </div>
                    <div className="col-md-3 text-block text-center usps">
                      <img alt="" src="/images/icon-network-2.png"/>
                        <h4>{ tr('become_v_usp_3') }</h4>
                        <p>{ tr('become_v_usp_3_par') }</p>
                    </div>
                    <div className="col-md-3 text-block text-center usps">
                      <img alt="" src="/images/icon-open.png"/>
                        <h4>{ tr('become_v_usp_4') }</h4>
                        <p>{ tr('become_v_usp_4_par') }</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content venue-requirements">
                <div className="half-image"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 text-block">
                      <h3>{ tr('become_v_req') }</h3>
                      <ul class="brush">
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('become_v_req_1') }</li>
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('become_v_req_2') }</li>
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('become_v_req_3') }</li>
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('become_v_req_4') }</li>
                      </ul>

                        <a href={"/" + this.props.match.params.lang + "/apply-venue" } className="button large no-float">{ tr('ce_apply') }</a>
                    </div>
                  </div>
                </div>
              </section>

            </div>
            );
        }
}


function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
        submitForm: (t, n, e, p, m, r) => dispatch(submitForm(t, n, e, p, m, r)),
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(BecomeAVenue);
