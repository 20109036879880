import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import {submitForm} from '../actions/submit-form';

import ModalVideo from 'react-modal-video';


class CreativeEntrepreneur extends React.Component {

    constructor(props) {
        super();
        this.state = {
            "name":     '',
            "email":    '',
            "phone":    '',
            "message":  '',
            "isOpen":   false
        };
        this.openModal = this.openModal.bind(this)
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    openModal () {
      this.setState({isOpen: true})
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    submitForm() {
        // check if all fields are filled
        if (this.state.name === '' ||
            this.state.email === '' ||
            this.state.phone === '' ||
            this.state.message === '' ) {

            return;
        }

        this.props.submitForm(
                'licensee',
                this.state.name,
                this.state.email,
                this.state.phone,
                this.state.message
        );
    }

    render() {
        /* make shortcut */
        var tr = this.props.translate;

        return (
            <div className="page creative-entrepeneur">

            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='gapB3vi2BL8' onClose={() => this.setState({isOpen: false})} />

            <section className="main main-title hero-image">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h1>{ tr('ce_become') }</h1>
                      <h2>{ tr('ce_start') }</h2>
                      <a onClick={() => this.setState({isOpen: true})} className="button large no-float"><i className="fa fa-play"></i>&nbsp;&nbsp;{ tr('ce_play_video') }</a>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content columns">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center text-block">
                      <h2>{ tr('ce_make_your_art') }</h2>
                    </div>
                    <div className="col-md-4 text-block text-center usps">
                      <img alt="" src="/images/icon-controle.png"/>
                        <h4>{ tr('ce_in_control') }</h4>
                        <p>{ tr('ce_in_control_par1') } { tr('ce_in_control_par2') }</p>
                    </div>
                    <div className="col-md-4 text-block text-center usps">
                      <img alt="" src="/images/icon-inspire.png"/>
                        <h4>{ tr('ce_inspire') }</h4>
                      <p>{ tr('ce_inspire_par1') } { tr('ce_inspire_par2') } { tr('ce_inspire_par3') }</p><br />
                      <a href={"/" + this.props.match.params.lang + "/apply" } className="button large no-float">{ tr('ce_apply') }</a>
                    </div>
                    <div className="col-md-4 text-block text-center usps">
                      <img alt="" src="/images/icon-network.png"/>
                        <h4>{ tr('ce_network') }</h4>
                        <p>{ tr('ce_network_par1') } { tr('ce_network_par2') } { tr('ce_network_par3') }</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content no-two-days">
                <div className="half-image"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 text-block">
                      <h3>{ tr('ce_no_two_days') }</h3>
                      <p>{ tr('ce_no_two_days_par1') }</p>
                      <ul className="brush">
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('ce_no_two_days_li1') }</li>
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('ce_no_two_days_li2') }</li>
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('ce_no_two_days_li3') }</li>
                        <li><img alt="" src="/images/icon-brush.png"/>{ tr('ce_no_two_days_li4') }</li>
                      </ul>

                      <a href={"/" + this.props.match.params.lang + "/apply" } className="button large no-float">{ tr('ce_apply') }</a>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content columns">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center text-block">
                      <h3>{ tr('ce_offer') }</h3>
                    </div>
                    <div className="col-md-4 text-center text-block usps">
                      <img alt="" src="/images/icon-system.png"/>
                        <h4>{ tr('ce_offer_system') }</h4>
                      <p>{ tr('ce_offer_system_par_1') }</p>
                    </div>
                    <div className="col-md-4 text-center text-block usps">
                      <img alt="" src="/images/icon-network-2.png"/>
                        <h4>{ tr('ce_offer_network') }</h4>
                      <p>{ tr('ce_offer_network_par_1') }</p>
                    </div>
                    <div className="col-md-4 text-center text-block usps">
                      <img alt="" src="/images/icon-grow.png"/>
                        <h4>{ tr('ce_offer_oppertunity') }</h4>
                      <p>{ tr('ce_offer_oppertunity_par_1') }</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content cta-image text-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h3>{ tr('ce_start_adventure') }</h3>
                        <a href={"/" + this.props.match.params.lang + "/apply" } className="button large no-float">{ tr('ce_apply') }</a>
                    </div>
                  </div>
                </div>
              </section>

            </div>
        );

    }
}


function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        submitForm: (t, n, e, p, m) => dispatch(submitForm(t, n, e, p, m)),

    };
};

export default connect(mapStateToProps, matchDispatchToProps)(CreativeEntrepreneur);
