import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class PartnerWithUs extends React.Component {
    render() {
        /* make shortcut */
        var tr = this.props.translate;

        return (
            <div className="partner page">
            <section className="main main-title">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h1>{ tr('partner_with_us') }</h1>
                      <h2 className="handwriting">{ tr('partner_join_family') }</h2>
                      <p className="intro">{ tr('partner_intro') }</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-1 text-block">
                      <h2>{ tr('partner_header1') }</h2>
                      <p><strong>{ tr('partner_paragraph1a') }</strong><br />{ tr('partner_paragraph1b') }</p>
                      <a href="host-a-paintnite-event" className="button normal">{ tr('partner_link1') }&nbsp;&nbsp;<i className="fa fa-angle-right"></i></a>
                    </div>
                    <div className="col-md-5 col-md-offset-1 image-stack">
                      <div className="stack rotate-right">
                        <img alt="" src="/images/placeholder.jpg"/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 col-md-offset-1 image-stack">
                      <div className="stack rotate-left">
                        <img alt="" src="/images/placeholder.jpg"/>
                      </div>
                    </div>
                    <div className="col-md-4 col-md-offset-1 text-block">
                      <h2>{ tr('partner_header2') }</h2>
                      <p><strong>{ tr('partner_paragraph2a') }</strong><br />{ tr('partner_paragraph2b') }</p>
                      <a href="become-an-event-organizer" className="button normal">{ tr('partner_link2')}&nbsp;&nbsp;<i className="fa fa-angle-right"></i></a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(PartnerWithUs);
