import React, {Component} from 'react';
import {connect} from 'react-redux';
import {unselectEvent} from '../actions/index'
import Rating from '../containers/rating';
import {sprintf} from 'sprintf-js';
import { getTranslate } from 'react-localize-redux';
import { FacebookProvider, Feed } from 'react-facebook';

//require('dotenv').config()

/* global $ */
class EventDetails extends Component {

    renderBuyButton(event_id, price, tickets_available) {
        if (tickets_available > 0) {
            return (
                <a href={ "/" + this.props.lang + "/cart/" + event_id}><button className="button buy">{ this.props.translate('event_details_buy_at') } {price}</button></a>
            );
        } else {
            return (
                <button className="button buy">{ this.props.translate('event_details_sold_out') }</button>
            );
        }
    }

    makeEventLink(event) {
        var title_link = event.event_name;
        title_link = title_link.replace(/ /g, "-");
        title_link = title_link.replace(/[^a-zA-Z0-9-]*/gi, "");
        //var link = process.env.REACT_APP_RETURN_URL + '/' + this.props.lang + '/event/' + event.event_id + "/" + title_link;
        var link = 'https://www.paintfest.com/' + this.props.lang + '/event/' + event.event_id + "/" + title_link;
        return link;
    }

    toggleMore(item) {
        $('.left .'+item).toggleClass('expanded');
    }

    render() {
        //console.log(this.props.event_id);
        if (!this.props.event) {
            $('body').removeClass('event-selected');
            return (
                <section className="event-details">
                </section>
            );
        }
        $('body').addClass('event-selected');

        var event = this.props.event;
        var tmpStyle = { background: "url( 'https://storage.googleapis.com/paintfest/" + event.artist_thumbnail + "')" };
        var eventPrice = (typeof(event) === undefined) ? '' : event.event_currency + " " + sprintf("%0.2f", event.event_price);
        var tr = this.props.translate;

        return (
            <FacebookProvider appId="111022139701836">
              <section className="event-details">
                <div className="event-details-background" onClick={() => this.props.unselectEvent()}></div>
                <div className="container">
              		<div className="row">
              			<div onClick={() => this.props.unselectEvent()} className="close-details"><i className="fa fa-times"></i></div>
              			<div className="col-md-12 event-title">
              				<h2>{this.props.event.event_name}</h2>
              			</div>
              			<div className="col-md-8 left">
              				<div className="painting">
              					<img alt="" src={"https://storage.googleapis.com/paintfest/" + event.painting_image} />
              				</div>
              				<div className="about">
              					<p>{event.event_details}</p>
              				</div>
              				<div className="artist col-md-12 expanded">
                                <div className="label-artist-venue">{ this.props.translate('event_details_artist_label') }</div>
                                <div className="avatar" style={tmpStyle} ></div>
              					<h4 className="name">{event.artist_displayname}</h4>
                                <Rating avg={event.artist_rating_avg} count={event.artist_rating_count}/>
              					<p>{event.artist_bio}</p>
              					<div className="more" onClick={() => this.toggleMore('artist')} ></div>
              				</div>
              				<div className="venue expanded">
                                <div className="label-artist-venue">{ this.props.translate('event_details_venue_label') }</div>
              					<h4 className="name">{ event.venue_name }</h4>
                                <Rating avg={event.venue_rating_avg} count={event.venue_rating_count}/>
              					<p>{event.venue_description}</p>
              					<div className="more" onClick={() => this.toggleMore('venue')} ></div>
              				</div>
              			</div>
              			<div className="col-md-4 right">
              				<div className="details">
              					<p className="text-label">{ this.props.translate('event_details_when') }</p>
              					<p className="location">{event.event_startdatetime}</p>
              					<p className="text-label">{ this.props.translate('event_details_where') }</p>
              					<p className="location"> { event.venue_name }<br/> {this.props.event.venue_address} {this.props.event.venue_city}</p>
              				</div>

                            <div className="link">
                                <a title={ tr('view_event_details') } href={ "/" + this.props.lang + "/event/" + this.props.event.event_id + "/" + this.props.event.event_url}>{ tr('view_event_details') }</a>
                            </div>

                            <div className="social-share">
                            </div>
                            <div className="map">
                            </div>
                            {event.start_info === "about_to_start" && <div className="reminders"><i className="fa fa-exclamation-circle"></i><p>{ this.props.translate('event_details_about_to_start') } </p></div> }
                            {event.start_info === "event_started" && <div className="reminders"><i className="fa fa-exclamation-circle"></i><p>{ this.props.translate('event_details_just_started') } </p></div> }
                            <div className="details buy">
              			       <p className="text-label">{event.event_tickets_available} { this.props.translate('event_details_tickets_left') } </p>
                               {this.renderBuyButton(event.event_id, eventPrice, event.event_tickets_available)}
              		        </div>

              		    </div>
              		</div>
              	</div>
            </section>
            </FacebookProvider>
        );
    }
}

/*      
                                // binnen de social share
                                <Feed link={ this.makeEventLink(event) }
                                      source={"https://storage.googleapis.com/paintfest/" + event.painting_image} >
                                      <button className="facebook" type="button"><i className="fa fa-facebook"></i>&nbsp;{ tr('share_on_facebook') }</button>
                                </Feed>
*/

// "state.activeUser" is set in reducers/index.js
function mapStateToProps(state) {
    return {
        event:          state.activeEvent,
        translate:      getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        unselectEvent:  (event) => dispatch(unselectEvent(event))
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(EventDetails);
