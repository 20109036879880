import React, {Component} from 'react';
import {connect} from 'react-redux';

class Rating extends Component {

    constructor( props ) {
        super( props );
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }

    getKey() {
        return this.keyCount++;
    }

    renderStars() {
        var r = parseFloat(this.props.avg);
        r = 4.5;
        var tmp = [];
        for (var i = 0; i < 5; i++) {
            if (r >= (i+1)) {
                tmp.push('on');
            }
            else if (r === (i + 0.5)) {
                tmp.push('half');
            } else {
                tmp.push('off');
            }
        }

        return tmp.map((flag) => {
            if (flag === 'on') {
                return (
                    <li key={this.getKey()}> <i className="fa fa-star"></i> </li>
                );
            }
            if (flag === 'half') {
                return (
                    <li key={this.getKey()}> <i className="fa fa-star-half-o"></i> </li>
                );
            }
            return (
                <li key={this.getKey()}> <i className="fa fa-star-o"></i> </li>
            );
        });
    }


    render() {
        return (
            <ul className="rating">
                {this.renderStars()}
                <li> {this.props.count} </li>
            </ul>
        );
    }
}

function mapStateToProps(state) {
   return { };
}

function matchDispatchToProps(dispatch) {
    return { };
};

export default connect(mapStateToProps, matchDispatchToProps)(Rating);
