import React, {Component} from 'react';
import {connect} from 'react-redux';
import {eventListFetchData} from '../actions/index';
import {updateAttendeeInfo, updateNrOfTickets} from '../actions/attendees';
import {updateReservation} from '../actions/update-reservation';
import { getTranslate } from 'react-localize-redux';

/* global $ */
class TicketQuantity extends Component {
    constructor(props) {
        super();

        this.state = {
            default_nr_of_tickets:  1,
            max_nr_of_tickets:      100
        }
    }

    clearOnFocus(e) {
        $("#"+e.target.id).removeClass("invalid");
    }

    changeTicketQuantity() {
        var nr_of_tickets = document.getElementById("nr_of_tickets").value;
        this.props.updateNrOfTickets(nr_of_tickets);

        var reservation_ref = (this.props.reservation != null) ? this.props.reservation.reservation_ref : '';
        if ( this.props.event != null) {
             this.props.updateReservation( this.props.event.event_id, reservation_ref, nr_of_tickets, this.props.attendees, this.props.coupons);
        }
    }

    renderTicketOptions() {
        var max_ticket_count = (this.props.event.event_tickets_available > this.state.max_nr_of_tickets)
                             ? this.state.max_nr_of_tickets : this.props.event.event_tickets_available;

        /* TODO: if no tickets are available any more, redirect to event page. */
        if (max_ticket_count === 0) {
            window.location.href = '/';
        }

        return [...Array(parseInt(max_ticket_count, 10))].map((u,idx) => {
            var nr_tickets = idx+1;

            if (nr_tickets === 1) {
                return ( <option key={nr_tickets} value={nr_tickets} >{nr_tickets} { this.props.translate('ticket') }</option> );
            }
            return ( <option key={nr_tickets} value={nr_tickets} >{nr_tickets} { this.props.translate('tickets') }</option> );
        })
    }

    updateAttendee(e) {
        this.props.updateAttendeeInfo(this.props.attendees, e.target.id, e.target.value);

        var reservation_ref = (this.props.reservation != null) ? this.props.reservation.reservation_ref : '';
        var selected_nr = (this.props.nr_of_tickets != null) ?
                                this.props.nr_of_tickets : this.state.default_nr_of_tickets
        ;

        if (selected_nr > this.props.event.event_tickets_available) {
            selected_nr = this.props.event.event_tickets_available;
        }

        if ( this.props.event != null) {
             this.props.updateReservation( this.props.event.event_id, reservation_ref, selected_nr, this.props.attendees, this.props.coupons);
        }
    }

    renderAttendees() {
        var selected_nr_of_tickets = (this.props.nr_of_tickets != null) ?
                                        this.props.nr_of_tickets : this.state.default_nr_of_tickets
        ;

        if (selected_nr_of_tickets > this.props.event.event_tickets_available) {
            selected_nr_of_tickets = this.props.event.event_tickets_available;
        }

        return [...Array(parseInt(selected_nr_of_tickets, 10))].map((u,idx) => {
            var nr_tickets = idx + 1;

            var firstname = '';
            var lastname = '';
            var email = '';

            if(this.props.attendees) {
                firstname = this.props.attendees['firstname_' + nr_tickets];
                lastname = this.props.attendees['lastname_' + nr_tickets];
                email = this.props.attendees['email_' + nr_tickets];
            }

            if (nr_tickets === 1) {
                return (
                    <div key={nr_tickets} className="ticket purchaser">
                        <div className="col-md-12 nested">
                            <p className="text-label uppercase">
                                {this.props.translate('ticket_purchaser')}
                            </p>
                        </div>
                        <div className="col-md-6 nested">
                            <i className="fa fa-user-o"></i>
                            <input id={"firstname_" + nr_tickets} type="text" defaultValue={firstname} placeholder={this.props.translate('attendee_firstname')} onFocus={(e) => this.clearOnFocus(e) } onBlur={(e) => this.updateAttendee(e)} />
                        </div>
                        <div className="col-md-6 nested">
                            <i className="fa fa-user-o"></i>
                            <input id={"lastname_" + nr_tickets} type="text" defaultValue={lastname} placeholder={ this.props.translate('attendee_lastname')} name="last_name" onFocus={(e) => this.clearOnFocus(e) } onBlur={(e) => this.updateAttendee(e)}/>
                        </div>
                        <div className="col-md-12 nested">
                            <i className="fa fa-envelope-o"></i>
                            <input id={"email_" + nr_tickets} type="text" defaultValue={email} placeholder={ this.props.translate('attendee_email')} name="email" onFocus={(e) => this.clearOnFocus(e) } onBlur={(e) => this.updateAttendee(e)}/>
                        </div>
                    </div>
                );
            }

            return (
                <div key={nr_tickets} className="ticket extra-ticket">
                    <div className="col-md-12 nested">
                        <p className="text-label uppercase">
                        Ticket {nr_tickets}
                        </p>
                    </div>
                    <div className="col-md-6 nested">
                        <i className="fa fa-user-o"></i>
                        <input id={"firstname_" + nr_tickets} type="text" defaultValue={firstname} placeholder={this.props.translate('attendee_firstname')} name="first_name" onBlur={(e) => this.updateAttendee(e)} />
                    </div>
                    <div className="col-md-6 nested">
                        <i className="fa fa-user-o"></i>
                        <input id={"lastname_" + nr_tickets} type="text" defaultValue={lastname} placeholder={this.props.translate('attendee_lastname')} name="last_name" onBlur={(e) => this.updateAttendee(e)} />
                    </div>
                    <div className="col-md-12 nested">
                        <i className="fa fa-envelope-o"></i>
                        <input id={"email_" + nr_tickets} type="text" defaultValue={email} placeholder={this.props.translate('attendee_email')} name="email" onBlur={(e) => this.updateAttendee(e)} />
                    </div>
                </div>
            );
        });
    }

    render() {
        if (this.props.event == null) {
            return (<div/>)
        }

        var selected_nr = (this.props.nr_of_tickets != null) ?
                           this.props.nr_of_tickets : this.state.default_nr_of_tickets
        ;
        return (
            <div>
                <h3>{this.props.translate('how_many_tickets')}</h3>
                <div className="select-wrapper">
                    <select defaultValue={selected_nr} id="nr_of_tickets" onChange={() => this.changeTicketQuantity()} >
                        {this.renderTicketOptions()}
                    </select>
                </div>
                <p className="text-label"> {this.props.translate('there_are')} {this.props.event.event_tickets_available} {this.props.translate('tickets_remaining')}</p>
                { this.renderAttendees() }
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       event:                   state.event_for_cart,
       selected_nr_of_tickets:  state.selected_nr_of_tickets,
       attendees:               state.attendees,
       nr_of_tickets:           state.nr_of_tickets,
       reservation:             state.reservation,
       coupons:                 state.coupons,
       translate:               getTranslate(state.locale),

    };
}

function matchDispatchToProps(dispatch) {
    return {
        fetchData:          (url)               => dispatch(eventListFetchData(url)),
        updateReservation:  (e, r, n, a, c)     => dispatch(updateReservation(e, r, n, a, c)),
        updateAttendeeInfo: (ai, n, v)          => dispatch(updateAttendeeInfo(ai, n, v)),
        updateNrOfTickets:  (nr_of_tickets)     => dispatch(updateNrOfTickets(nr_of_tickets)),
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(TicketQuantity);
