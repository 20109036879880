import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class ThankYouForm extends React.Component {

    render () {
        /* make shortcut */
        var tr = this.props.translate;

        return (
            <div className="about-us page">
              <section className="main main-title">
              	<div className="container">
              		<div className="row">
              			<div className="col-md-12 text-center">
              			  <h1>{ tr('ty_form_header') }</h1>
              			  <h2 className="handwriting">{ tr('ty_form_handwrite') }</h2>
                      <p className="intro">{ tr('ty_form_intro') }</p>
          			     </div>
          		    </div>
          	    </div>
              </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(ThankYouForm);
