import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectEventForCart} from '../actions/index'
import EventDate from '../containers/eventdate';
import Rating from '../containers/rating';
import { getTranslate } from 'react-localize-redux';


class EventReminder extends Component {
    constructor(props) {
        super();
    }

    componentDidMount() {
        this.props.selectEventForCart(this.props.event_id);
    }

    render() {
        //console.log("render eventreminder");
        var event = this.props.event;

        if (event == null) {
            return (
                <div className="col-md-3 tile"/>
            );
        }
        var tmpStyle = { background: "url( 'https://storage.googleapis.com/paintfest/" + event.artist_thumbnail + "')" };

        return (
            <div className="col-md-3 tile">
              <div className="item">
                <div className="image">
                  <img alt="" src={"https://storage.googleapis.com/paintfest/" + event.painting_image} />
                  <EventDate datetime={event.event_startdatetime} />
                </div>
                <div className="details">
                  <h3 className="title"> {event.event_name} </h3>
                  <p className="location">{event.venue_name} { this.props.translate('in') } {event.venue_city}</p>
                </div>
                <div className="artist">
                  <div className="avatar" style={tmpStyle} ></div>
                  <h4 className="name">{event.artist_firstname} {event.artist_lastname}</h4>
                  <Rating avg={event.artist_rating_avg} count={event.artist_rating_count}/>
                </div>
              </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       event:               state.event_for_cart,
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        selectEventForCart:   (event_id) => dispatch(selectEventForCart(event_id))
    };
};


export default connect(mapStateToProps, matchDispatchToProps)(EventReminder);
