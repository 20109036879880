
/* events selected */
export function updateAttendeeInfo(attendee_info, name, value) {

    // console.log('updateAttendee called for', name, value);

    // update the attendee info here.

    // attendee is a simple mapping of
    // firstname_1 => firstname attendee 1
    // lastname_1 => lastname attendee 1
    // firstname_2 => firstname attendee 2
    // lastname_2 => lastname attendee 2
    // email_2 => email attendee 2

    if (attendee_info == null) {
        attendee_info = [];
    }

    attendee_info[name] = value;

    // console.log(attendee_info);

    // update data
    return {
        type: 'ATTENDEES_UPDATED',
        payload: attendee_info
    }
};


export function updateNrOfTickets(nr_of_tickets) {
    return {
        type: 'NR_OF_TICKETS_UPDATED',
        payload: nr_of_tickets,
    }
};
