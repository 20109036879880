import React, { Component } from 'react';

import TicketQuantity from '../containers/ticketquantity';
import Coupons from '../containers/coupons';
import AgreeContinue from '../containers/agreecontinue';
import EventReminder from '../containers/eventreminder';
import PFCookie from '../containers/pfcookie';

class Cart extends Component {
    render () {
        //console.log(this.props.match.params.event_id);
        //console.log("cart lang", this.props.match.lang);
                            //onder ticket quantity - <Coupons />
        return (
            <section className="cart-content">
        		<div className="container">
        			<div className="row">
        				<div className="col-md-7 col-md-offset-1 number-of-tickets">
                            <PFCookie event_id={this.props.match.params.event_id} />
                            <TicketQuantity />
                            <Coupons />
                            <AgreeContinue lang={this.props.lang}/>
        				</div>
                        <EventReminder event_id={this.props.match.params.event_id}/>
        			</div>
        		</div>
        	</section>
        );
    }
}

export default Cart
