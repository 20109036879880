import React, {Component} from 'react';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import {sprintf} from 'sprintf-js';

require('dotenv').config()


/* global $ */
class AgreeContinue extends Component {

    constructor(props) {
        super();
    }

    continueToPayment() {
        if (this.props.reservation === null || this.props.reservation.reservation_ref === ''){
            //console.log(this.props.reservation);
            //console.log("no ref or reservation");
            return false;
        }

        // check if tick box is ticked
        if (typeof $("#agreement:checked").val() === 'undefined') {
            return;
        }

        // check first attendee (which is customer email)
        var $fn = $("#firstname_1").val();
        var $em = $("#email_1").val();

        var $fail = false;
        if ( typeof($fn) === 'undefined' || $fn.length < 2) {
            //console.log("firsname not entered");
            $("#firstname_1").addClass("invalid");
            $fail = true;
        }
        if ( typeof($em) === 'undefined') {
            //console.log("email not entered");
            $("#email_1").addClass("invalid");
            $fail = true;
        } else {
            // this version complaints about unneeded escaping of [
            // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test($em)) {
                $("#email_1").addClass("invalid");
                //console.log("email false");
                $fail = true;
            }
        }
        if ($fail) {
            return;
        }

        // get event id (complicated way
        var url_parts = window.location.href.split("/");
        var event_id = url_parts[url_parts.length-1];

        //var backURL = process.env.REACT_APP_RETURN_URL
        //var backURL = 'http://paintfest.tk:8080/' 
        var backURL = 'https://www.paintfest.com/'
                    + window.location.pathname.substr(1,2) + '/thankyou?_=1&referenceID='
                    + this.props.reservation.reservation_ref
                    + '&eventID=' + event_id;

        //var action_url = process.env.REACT_APP_API_BASE
        /*
        var action_url = 'https://api.paintfest.com'
                       + '/api/event/getpurchaseurl/'
                       + this.props.reservation.reservation_ref
                       + '?backURL=' + encodeURIComponent(backURL);

        */
        var action_url = 'https://pay.paintfest.com'
                       + '/pay2?ref=' + this.props.reservation.reservation_ref
                       + '&event=' + event_id 
                       + '&backUrl=' + encodeURIComponent(backURL);


        fetch(action_url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                console.log(response)
                return response;
            })
            .then((response) => response.json())
            .then((data) => window.location = data)
    }

    render() {
        var tr = this.props.translate;

        /* retrieve price from reservation - if there is none yet, use price from event */
        var total_price = undefined;
        var sub_total = undefined;
        var total_discount = undefined;

        /* price */
        if ( this.props.reservation !== null && typeof(this.props.reservation.total_price) !== "undefined") {
            total_price = sprintf("%0.2f", this.props.reservation.total_price);
        } else if (this.props.event_for_cart !== null) {
            total_price = sprintf("%0.2f", this.props.event_for_cart.event_price);
        }

        /* discount */
        if ( this.props.reservation !== null && typeof(this.props.reservation.total_discount) !== "undefined"
                && this.props.reservation.total_discount > 0) {
            total_discount = sprintf("%0.2f", this.props.reservation.total_discount);
            sub_total = sprintf("%0.2f", this.props.reservation.total_discount + this.props.reservation.total_price);
        }

        return (
            <div>
                <div className="agreement">
                    <div className="col-md-12 nested">
                        <input id="agreement" type="checkbox" name="agreement" value="agreed" />
                        <label htmlFor="agreement">{ tr('agree_legal') }</label>
                    </div>

                    <div className="actions table">
                        <div className="col-md-6 col-sm-6 totals text-right">
                            {sub_total !== undefined && <p><strong> { tr('tickets_total') }</strong></p>}
                        </div>
                        <div className="col-md-3 col-sm-6 totals">
                            {sub_total !== undefined && <p><strong>€ {sub_total}</strong></p>}
                        </div>

                        <div className="col-md-6 col-sm-6 totals text-right">
                            {total_discount !== undefined && <p><strong> { tr('total_discount') }</strong></p>}
                        </div>
                        <div className="col-md-3 col-sm-6 totals">
                            {total_discount !== undefined && <p><strong>- € {total_discount}</strong></p>}
                        </div>
                        <div className="col-md-6 col-sm-6 your totals text-right">
                            {total_price !== undefined && <p><strong> { tr('your_total') }</strong></p>}
                        </div>
                        <div className="col-md-3 col-sm-6 your totals">
                            {total_price !== undefined && <p><strong>€ {total_price}</strong></p>}
                        </div>
                    </div>
                    <div className="actions">
                      <div className="col-md-12 total">
                          <button onClick={() => this.continueToPayment()} className="button buy">{ tr('continue_payment') } </button>
                      </div>
                    </div>
                </div>
                <div className="agree-terms">
                    <div className="col-md-12 nested text-center">
                        <p className="small">{ tr('agree_terms') }</p>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       reservation:             state.reservation,
       event_for_cart:          state.event_for_cart,
       translate:               getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return { };
};

export default connect(mapStateToProps, matchDispatchToProps)(AgreeContinue);
