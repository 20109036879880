import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {submitForm} from '../actions/submit-form';

require('dotenv').config()

/* global $ */
class ApplyArtist extends React.Component {

    constructor(props) {
        super();
        this.state = { };
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit(e) {
        // check if all values are filled.
        var $error = false;
        if ( $("#firstname").val() < 2 ) {
            $error = true;
            $("#firstname").addClass("invalid");
        }
        var $notnulls = ['#email', '#phone', '#address_1', '#city', '#resume', '#cover'];
        for (var $i=0; $i < $notnulls.length; $i++) {
            var $label = $notnulls[$i];
            if ( $($label).val() === '') {
                $error = true;
                $($label).addClass("invalid");
                $($label).one('focus', function(e) { $("#"+e.target.id).removeClass('invalid'); } );
            }
        }
        if ($error === false) {
            $("#artist_application").submit();
        }
        return;
    }

    submitForm() {
    }

    renderLanguages() {

        var languages = [ 'Bulgarian','Croatian','Czech','Danish','Dutch',
            'English','Estonian','Finnish','French','German','Greek','Hungarian',
            'Irish','Italian','Latvian','Lithuanian','Luxembourgish','Maltese',
            'Norwegian','Polish','Portuguese','Romanian','Russian','Slovak',
            'Slovenian','Spanish','Swedish','Ukrainian'
        ];

        return languages.map((language) => {
            return (
                <label key={language} className="language-label" htmlFor={language}>{language}
                    <input type="checkbox" id={language} name="artist_application_form[languages][]" value={language} />
                </label>
            );
        });
    }

    render() {
        /* make shortcut */
        var tr = this.props.translate;
        return (
                <div className="apply page">
                  <section className="main main-title">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8 col-md-offset-2 text-center">
                          <h1>{ tr('apply_as_artist') }</h1>
                          <p className="intro">{ tr('apply_par1') } { tr('apply_par2') } { tr('apply_par3') }</p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="main-content form">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                          <form id="artist_application" className="contact-form" action={ "https://api.paintfest.com/apply/artist" } name="artist_application" method="post" encType="multipart/form-data">
                            <label htmlFor="firstname">{ tr('form_firstname') }</label>
                            <input type="text" id="firstname" name="artist_application_form[firstname]" placeholder={ tr('form_firstname') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="lastname">{ tr('form_lastname') }</label>
                            <input type="text" id="lastname" name="artist_application_form[lastname]" placeholder={ tr('form_lastname') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="email">{ tr('form_email') }</label>
                            <input type="text" id="email" name="artist_application_form[email]" placeholder={ tr('form_email') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="phone">{ tr('form_phone') }</label>
                            <input type="text" id="phone" name="artist_application_form[phone]" placeholder={ tr('form_phone') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="address_1">{ tr('form_address_1') }</label>
                            <input type="text" id="address_1" name="artist_application_form[address1]" placeholder={ tr('form_address_1') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="address_2">{ tr('form_address_2') }</label>
                            <input type="text" id="address_2" name="artist_application_form[address2]" placeholder={ tr('form_address_2') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="postalcode">{ tr('form_postalcode') }</label>
                            <input type="text" id="postalcode" name="artist_application_form[postcode]" placeholder={ tr('form_postalcode') } onChange={(e) => this.handleChange(e) } /><br />

                            <label htmlFor="city">{ tr('form_city') }</label>
                            <input type="text" id="city" name="artist_application_form[city]" placeholder={ tr('form_city') } onChange={(e) => this.handleChange(e) } /><br />

                            {/* COUNTRY SELECT */}
                            <label htmlFor="country">{ tr('form_country') }</label>
                            <div className="select-wrapper">
                              <select id="country" name="artist_application_form[country]">
                                <option>Austria </option>
                                <option>Belgium </option>
                                <option>Bulgaria    </option>
                                <option>Croatia </option>
                                <option>Cyprus  </option>
                                <option>Czech Republic  </option>
                                <option>Denmark </option>
                                <option>Estonia </option>
                                <option>Finland </option>
                                <option>France  </option>
                                <option>Germany </option>
                                <option>Greece  </option>
                                <option>Hungary </option>
                                <option>Ireland </option>
                                <option>Italy</option>
                                <option>Latvia</option>
                                <option>Lithuania</option>
                                <option>Luxembourg</option>
                                <option>Malta</option>
                                <option>Netherlands</option>
                                <option>Poland</option>
                                <option>Portugal</option>
                                <option>Romania</option>
                                <option>Slovakia</option>
                                <option>Slovenia</option>
                                <option>Spain</option>
                                <option>Sweden</option>
                              </select>
                            </div>
                            <div className="clear"></div>
                            <label htmlFor="state">{ tr('form_state') }</label>
                            <input type="text" id="state" name="artist_application_form[state]" placeholder={ tr('form_state') } />
                            <div className="clear"></div><br />

                            {/* LANGUAGES SPOKEN CHECKBOX */}
                            <div className="check_group">
                              <label>{ tr('form_languages') }</label>
                              <div className="columns">
                              { this.renderLanguages() }
                              </div>
                            </div>
                            <div className="clear"></div><br /><br />
                            <p><strong>{ tr('form_tell_us') }</strong></p>
                            <label className="full-width" htmlFor="why_launch">{ tr('form_why_launch') }</label>
                            <textarea id="why_launch" name="artist_application_form[info-one]" placeholder={ tr('form_why_launch') }  /><br />

                            <label className="full-width" htmlFor="most_proud_of">{ tr('form_most_proud_of') }</label>
                            <textarea id="most_proud_of" name="artist_application_form[info-two]" placeholder={ tr('form_most_proud_of') } /><br />

                            <label className="full-width" htmlFor="why_addition">{ tr('form_why_addition') }</label>
                            <textarea id="why_addition" name="artist_application_form[info-three]" placeholder={ tr('form_why_addition') } /><br />

                            <div className="clear"></div>
                            <label htmlFor="resume">{ tr('form_file_resume') }</label>
                            <input type="file" id="resume" name="artist_application_form[resume-file]" />
                            <div className="clear"></div>

                            <label htmlFor="cover">{ tr('form_file_cover') }</label>
                            <input type="file" id="cover" name="artist_application_form[coverletter-file]" />
                            <div className="clear"></div>

                            <label htmlFor="additional">{ tr('form_file_additional') }</label>
                            <input type="file" id="additional" name="artist_application_form[additional-files][]" multiple />
                            <div className="clear"></div>
                            <input onClick={(e) => this.handleSubmit(e) } className="button no-float ghost" name="artist_application_form[save]" type="button" value={ tr('form_submit') } />

                            <input type="hidden" name="artist_application_form[locale]" value={this.props.match.params.lang} />
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
            );
        }
}


function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
        submitForm: (t, n, e, p, m, r) => dispatch(submitForm(t, n, e, p, m, r)),
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(ApplyArtist);
