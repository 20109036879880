require('dotenv').config();

/* events selected */
export function submitForm(type, name, email, phone, message, refcode) {
    //console.log('submitForm called for ', type);

    //var url = process.env.REACT_APP_API_BASE + '/api/question/' + type;
    var url = 'https://api.paintfest.com/api/question/' + type;
    var formdata = {
        'name':     name,
        'email':    email,
        'phone':    phone,
        'message':  message,
        'refcode':  refcode
    };

    return (dispatch) => {
        fetch( url, {
                method:     'post',
                body:       JSON.stringify(formdata)
            }).then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(submitFormProcessed(data)));
    };
};

export function submitFormProcessed(data) {
    window.location = 'form-submitted'
}
