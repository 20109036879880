import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {connect} from 'react-redux';
import {loadReservation} from '../actions/load-reservation';
import {updateReservation} from '../actions/update-reservation';

class PFCookie extends Component {
    componentWillMount() {
        const { cookies } = this.props;
        var reservation_ref = cookies.get('reservation_ref');

        if (reservation_ref  && reservation_ref !== '') {
            this.props.loadReservation(reservation_ref, this.props.event_id);
        }
        else {
            //console.log("should create new reference");
            this.props.updateReservation(this.props.event_id, '', 1, null, null);
        }
    }

    render() {
        console.log("rendering pfcookie");

        // thank you page called. Clear cookie.
        var tmp_location = window.location + '';
        if (tmp_location.indexOf('thankyou') > -1) {
            const { cookies } = this.props;
            //cookies.remove('reservation_ref', { path: '/' });
            return ( <div> </div> );
        }

        if (this.props.reservation === null) {
            return ( <div></div> );
        }
        const { cookies } = this.props;

        //cookies.set('reservation_ref', this.props.reservation.reservation_ref, { path: '/' });
        return ( <div> </div> );
    }
}
PFCookie.propTypes = {
    cookies: instanceOf(Cookies).isRequired
}


function mapStateToProps(state) {
   return {
       event:                   state.event_for_cart,
       reservation:             state.reservation,
    };
}

function matchDispatchToProps(dispatch) {
    return {
        loadReservation:    (reservation_ref, event_id)     => dispatch(loadReservation(reservation_ref, event_id)),
        updateReservation:  (e, r, n, a, c)                 => dispatch(updateReservation(e, r, n, a, c)),

    };
};

export default connect(mapStateToProps, matchDispatchToProps)(withCookies(PFCookie));
