import { render } from 'react-dom/cjs/react-dom.production.min';

require('dotenv').config();
/* ticket quantity stuff */

/* events selected */
export function selectEventForCart(event_id) {
    //var url = process.env.REACT_APP_API_BASE + '/api/event/get_event_details?event_id=' + event_id;
    var url = 'https://api.paintfest.com/api/event/get_event_details?event_id=' + event_id;
    console.log("selectEventForCart: retrieving: ", url);

    return (dispatch) => {
        //dispatch(eventListIsLoading(true));
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                //dispatch(eventListIsLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(eventFetchForCartSuccess(data)))
            //.catch(() => dispatch(eventListHasErrored(true)));
    };
};

export function eventFetchForCartSuccess(data) {
    console.log("eventFetchForCartSuccess: event retrieved: ", data.event.event_id);
    return {
        type: 'EVENT_LOADED',
        payload: data.event
    }
}

export function selectEvent(event) {
    //var url = process.env.REACT_APP_API_BASE + '/api/event/get_event_details?event_id=' + event.event_id;
    var url = 'https://api.paintfest.com/api/event/get_event_details?event_id=' + event.event_id;
    console.log("selectEvent: retrieving: ", url);

    return (dispatch) => {
        //dispatch(eventListIsLoading(true));
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                //dispatch(eventListIsLoading(false));
                console.log("hallo");
                console.log(response);
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(eventFetchDataSuccess(data)));
            //.catch(() => dispatch(eventListHasErrored(true)));
    };
};

export function eventFetchDataSuccess(data) {
    console.log("event data found: " + data.event);
    return {
        type: 'EVENT_SELECTED',
        payload: data.event
    }
}


export function unselectEvent() {
    //console.log("You un-clicked on an event");
    return {
        type: 'EVENT_SELECTED',
        payload: null
    }
};


export function reloadButtonPressed() {
    //console.log("You clicked on the reload button");
    var eventListRequested = 1;
    return {
        type: 'EVENTLIST_REQUESTED',
        eventListRequested
    }
};

export function eventListHasErrored(bool) {
    return {
        type: 'EVENTLIST_HAS_ERRORED',
        hasErrored: bool
    };
}

export function eventListIsLoading(bool) {
    return {
        type: 'EVENTLIST_IS_LOADING',
        isLoading: bool
    };
}

export function eventListFetchDataSuccess(eventlist, nr_guests, location) {
    // If number of guests is given, filter events on nr of guests
    //console.log("nr of guests:" + nr_guests);
    if (typeof(nr_guests) !== "undefined") {
        var filtered_events = [];
        for (var i=0; i < eventlist.events.length; i++) {
            var e = eventlist.events[i];
            if (e.event_tickets_available >= nr_guests ) {
                filtered_events.push(e);
            }
        }
        eventlist.events = filtered_events;
    }

    // update city for location
    if ('address' in location) {
        var p = location.address.split(",")
        location.city = p[0];
    } else {
        location.city = 'Amsterdam';
    }

    return function(dispatch) {
        dispatch({
            type: 'EVENTLIST_HAS_ERRORED',
            hasErrored: false
        })
        dispatch({
            type: 'EVENTLIST_FETCH_DATA_SUCCESS',
            eventlist
        })
        dispatch({
            type: 'SEARCHLOCATION_UPDATED',
            location,
        })
    }
};


export function eventListFetchData(url, nr_guests, location) {
    console.log("retrieving url:: ", url);
    console.log("guests: ", nr_guests);
    console.log("location:", location);

    return (dispatch) => {
        dispatch(eventListIsLoading(true));
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(eventListIsLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((eventlist) => dispatch(eventListFetchDataSuccess(eventlist, nr_guests, location)))
            .catch(() => dispatch(eventListHasErrored(true)));
    };
}

export function updateLocation(location) {
    return {
        type: 'LOCATION_IS_UPDATED',
        location
    };
}

export function updateEventAddress(full_address, old_location) {
    // only show first part left of the comma
    var location = {};
    if (typeof(old_location) !== 'undefined') {
        var tmp = JSON.stringify(old_location);
        location = JSON.parse(tmp);
    }

    location.address = full_address;

    return {
        type:       'SEARCHLOCATION_UPDATED',
        location
    }
}
