import {eventListIsLoading, eventListHasErrored,eventListFetchDataSuccess} from '../actions/index'
import moment from 'moment';
require('dotenv').config();

/* events selected */
export function loadLocation(go_fetch) {
    //console.log('loadLocation() called');
    var url = 'https://freegeoip.net/json/';

    return loadLocationSuccess('', 1);
    /*
    return (dispatch) => {
            fetch(url)
            .then((response) => {
                if (!response.ok) {
                    //throw Error(response.statusText);
                    return False
                }
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(loadLocationSuccess(data, go_fetch)));
    };
    */
};

export function loadLocationSuccess(data, go_fetch) {
    //console.log( "loadLocationSuccess: location retrieved: ", JSON.stringify(data) );

    if (data.city === "") {
        data.city = "Amsterdam";
    }

    var location = {
        "city":     data.city,
        "address":  data.city + ", " + data.country_name,
        "lat":      data.latitude,
        "lng":      data.longitude
    };

    if (go_fetch === true) {
        var from = moment().format('YYYYMMDD');
        var till = moment().add(13, 'weeks').format('YYYYMMDD');

        //var url = process.env.REACT_APP_API_BASE + '/api/event/get_events_for_location?lat=' +
        //            data.latitude + '&lng=' + data.longitude + '&dst=100&start='+from+'&end='+till;
        var url = 'https://api.paintfest.com/api/event/get_events_for_location?lat=' +
                    data.latitude + '&lng=' + data.longitude + '&dst=100&start='+from+'&end='+till;

        return (dispatch) => {
            dispatch(eventListIsLoading(true));
            fetch(url)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    dispatch(eventListIsLoading(false));
                    return response;
                })
                .then((response) => response.json())
                .then((eventlist) => dispatch(eventListFetchDataSuccess(eventlist, 1, location)))
                .catch(() => dispatch(eventListHasErrored(true)));
        };
    }

    return {
        type:       'SEARCHLOCATION_UPDATED',
        location
    }
}
