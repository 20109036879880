import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {submitForm} from '../actions/submit-form';

require('dotenv').config()

/* global $ */

class ApplyVenue extends React.Component {

    constructor(props) {
        super();
        this.state = { };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit(e) {
        // check if all values are filled.
        var $error = false;
        if ( $("#firstname").val() < 2 ) {
            $error = true;
            $("#firstname").addClass("invalid");
        }
        var $notnulls = ['#venue-name', '#email', '#phone', '#address_1', '#city'];
        for (var $i=0; $i < $notnulls.length; $i++) {
            var $label = $notnulls[$i];
            if ( $($label).val() === '') {
                $error = true;
                $($label).addClass("invalid");
                $($label).one('focus', function(e) { $("#"+e.target.id).removeClass('invalid'); } );
            }
        }
        if ($error === false) {
            $("#venue_application").submit();
        }
        return;
    }

    renderLanguages() {
        var languages = [ 'Bulgarian','Croatian','Czech','Danish','Dutch',
            'English','Estonian','Finnish','French','German','Greek','Hungarian',
            'Irish','Italian','Latvian','Lithuanian','Luxembourgish','Maltese',
            'Norwegian','Polish','Portuguese','Romanian','Russian','Slovak',
            'Slovenian','Spanish','Swedish','Ukrainian'
        ];

        return languages.map((language) => {
            return (
                <label key={language} className="language-label" htmlFor={language}>{language}
                    <input type="checkbox" id={language} name="venue_application_form[[languages][]" value={language} />
                </label>
            );
        });
    }

    render() {
        /* make shortcut */
        var tr = this.props.translate;
        return (
                <div className="apply page">
                  <section className="main main-title">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 col-md-offset-3 text-center">
                          <h1>{ tr('become_a_venue') }</h1>
                          <p className="intro">{ tr('become_a_venue_par') }</p>
                        </div>
                      </div>
                    </div>
                  </section>


                  <section className="main-content form">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                          <form id="venue_application" className="contact-form" action={ 'https://www.paintfest.com/' + "/apply/venue" } name="venue_application_form[" method="post" encType="multipart/form-data">
                            <h4>The Venue</h4>
                            <label htmlFor="venue-name">{ tr('venue_name') }</label>
                            <input type="text" id="venue-name" name="venue_application_form[venue-name]" placeholder={ tr('venue_name') } onChange={(e) => this.handleChange(e) } /><br />

                            <div className="radio_group">
                              <label>{ tr('venue_alcohol') }</label>
                              <label class="radio-label" for="yes">{ tr('checkbox_yes') }
                                <input type="radio" id="yes" name="venue_application_form[alcohol]" value="{ tr('checkbox_yes') }" />
                              </label>
                              <label class="radio-label" for="no">{ tr('checkbox_no') }
                                <input type="radio" id="no" name="venue_application_form[alcohol]" value="{ tr('checkbox_no') }" />
                              </label>
                            </div>

                            <div className="clear"></div><br />

                            {/* FLOORING SELECT */}
                            <label htmlFor="flooring">{ tr('venue_flooring') }</label>
                            <div className="select-wrapper">
                              <select id="venue-flooring" name="venue_application_form[venue-flooring]">
                                <option>{ tr('venue_flooring_carpeted') }</option>
                                <option>{ tr('venue_flooring_not_carpeted') }</option>
                              </select>
                            </div>
                            <div className="clear"></div><br />

                            {/* DAYS */}
                            <div className="check_group">
                              <label>{ tr('venue_which_nights') }</label>
                              <label class="language-label" for="{ tr('checkbox_su') }">{ tr('checkbox_su') }
                                <input id="{ tr('checkbox_su') }" name="venue_application_form[availability][]" value="{ tr('checkbox_su') }" type="checkbox" />
                              </label>
                              <label class="language-label" for="{ tr('checkbox_mo') }">{ tr('checkbox_mo') }
                                <input id="{ tr('checkbox_mo') }" name="venue_application_form[availability][]" value="{ tr('checkbox_mo') }" type="checkbox" />
                              </label>
                              <label class="language-label" for="{ tr('checkbox_tu') }">{ tr('checkbox_tu') }
                                <input id="{ tr('checkbox_tu') }" name="venue_application_form[availability][]" value="{ tr('checkbox_tu') }" type="checkbox" />
                              </label>
                              <label class="language-label" for="{ tr('checkbox_we') }">{ tr('checkbox_we') }
                                <input id="{ tr('checkbox_we') }" name="venue_application_form[availability][]" value="{ tr('checkbox_we') }" type="checkbox" />
                              </label>
                              <label class="language-label" for="{ tr('checkbox_th') }">{ tr('checkbox_th') }
                                <input id="{ tr('checkbox_th') }" name="venue_application_form[availability][]" value="{ tr('checkbox_th') }" type="checkbox" />
                              </label>
                              <label class="language-label" for="{ tr('checkbox_fr') }">{ tr('checkbox_fr') }
                                <input id="{ tr('checkbox_fr') }" name="venue_application_form[availability][]" value="{ tr('checkbox_fr') }" type="checkbox" />
                              </label>
                              <label class="language-label" for="{ tr('checkbox_sa') }">{ tr('checkbox_sa') }
                                <input id="{ tr('checkbox_sa') }" name="venue_application_form[availability][]" value="{ tr('checkbox_sa') }" type="checkbox" />
                              </label>
                            </div>
                            <div className="clear"></div><br />

                            {/* FREQUENCY */}
                            <label htmlFor="frequency">{ tr('venue_frequency') }</label>
                            <div className="select-wrapper">
                              <select id="frequency" name="venue_application_form[frequency]">
                                <option>{ tr('checkbox_freq_weekly') }</option>
                                <option>{ tr('checkbox_freq_biweekly') }</option>
                                <option>{ tr('checkbox_freq_monthly') }</option>
                                <option>{ tr('checkbox_freq_season') }</option>
                              </select>
                            </div>
                            <div className="clear"></div><br />
                            <label htmlFor="venue-seating">{ tr('venue_seating') }</label>
                            <input type="text" id="venue-seating" name="venue_application_form[venue-seating]" placeholder={ tr('venue_number_seats') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="venue-website">{ tr('label_website') }</label>
                            <input type="text" id="venue-website" name="venue_application_form[venue-website]" placeholder={ tr('label_website') } onChange={(e) => this.handleChange(e) } /><br />
                            <div className="clear"></div>
                            <label htmlFor="venue-photos">{ tr('venue_photos') }</label>
                            <input type="file" id="venue-photos" name="venue_application_form[venue-photos][]" multiple />
                            <div className="clear"></div>
                            <p><strong></strong></p>
                            <textarea id="additional" name="venue_application_form[additional]" placeholder={ tr('venue_optional') }  />

                            <div className="clear"></div><br />

                            <h4>{ tr('label_your_details') }</h4>
                            <label htmlFor="firstname">{ tr('form_firstname') }</label>
                            <input type="text" id="firstname" name="venue_application_form[firstname]" placeholder={ tr('form_firstname') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="lastname">{ tr('form_lastname') }</label>
                            <input type="text" id="lastname" name="venue_application_form[lastname]" placeholder={ tr('form_lastname') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="email">{ tr('form_email') }</label>
                            <input type="text" id="email" name="venue_application_form[email]" placeholder={ tr('form_email') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="phone">{ tr('form_phone') }</label>
                            <input type="text" id="phone" name="venue_application_form[phone]" placeholder={ tr('form_phone') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="address_1">{ tr('form_address_1') }</label>
                            <input type="text" id="address_1" name="venue_application_form[address1]" placeholder={ tr('form_address_1') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="address_2">{ tr('form_address_2') }</label>
                            <input type="text" id="address_2" name="venue_application_form[address2]" placeholder={ tr('form_address_2') } onChange={(e) => this.handleChange(e) } /><br />
                            <label htmlFor="postalcode">{ tr('form_postalcode') }</label>
                            <input type="text" id="postalcode" name="venue_application_form[postcode]" placeholder={ tr('form_postalcode') } onChange={(e) => this.handleChange(e) } /><br />

                            <label htmlFor="city">{ tr('form_city') }</label>
                            <input type="text" id="city" name="venue_application_form[city]" placeholder={ tr('form_city') } onChange={(e) => this.handleChange(e) } /><br />

                            {/* COUNTRY SELECT */}
                            <label htmlFor="country">{ tr('form_country') }</label>
                            <div className="select-wrapper">
                              <select id="country" name="venue_application_form[country]">
                                <option>Austria </option>
                                <option>Belgium </option>
                                <option>Bulgaria    </option>
                                <option>Croatia </option>
                                <option>Cyprus  </option>
                                <option>Czech Republic  </option>
                                <option>Denmark </option>
                                <option>Estonia </option>
                                <option>Finland </option>
                                <option>France  </option>
                                <option>Germany </option>
                                <option>Greece  </option>
                                <option>Hungary </option>
                                <option>Ireland </option>
                                <option>Italy</option>
                                <option>Latvia</option>
                                <option>Lithuania</option>
                                <option>Luxembourg</option>
                                <option>Malta</option>
                                <option>Netherlands</option>
                                <option>Poland</option>
                                <option>Portugal</option>
                                <option>Romania</option>
                                <option>Slovakia</option>
                                <option>Slovenia</option>
                                <option>Spain</option>
                                <option>Sweden</option>
                              </select>
                            </div>
                            <div className="clear"></div>
                            <label htmlFor="state">{ tr('form_state') }</label>
                            <input type="text" id="state" name="venue_application_form[state]" placeholder={ tr('form_state') } />
                            <div className="clear"></div><br />

                            <input onClick={(e) => this.handleSubmit(e) } className="button no-float ghost" name="venue_application_form[save]" type="button" value={ tr('form_submit') } />

                            <input type="hidden" name="venue_application_form[locale]" value={this.props.match.params.lang} />
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
            );
        }
}


function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
        submitForm: (t, n, e, p, m, r) => dispatch(submitForm(t, n, e, p, m, r)),
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(ApplyVenue);
