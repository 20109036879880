import md5 from 'js-md5';
require('dotenv').config();

/* ticket quantity stuff */

/* events selected */
export function updateReservation(event_id, reservation_ref, nr_of_tickets, attendees, coupons) {

    //console.log('updateReservation called for ', event_id);
    if (event_id == null || event_id === '') {
        return {
            type: 'RESERVATION_RESPONSE',
            payload: null
        }
    }

    /* build values for API */
    var request = {};
    request['event_id'] = event_id;
    request['number_of_tickets'] = nr_of_tickets || 1;
    if (coupons){
      request['coupons'] = coupons.sort();
    } else {
      request['coupons'] = coupons;
    }
    /*
    if (request['coupons'] !== null ) {
        if (request['coupons'].length > 0) {
            request['coupons'] = request['coupons'].join(',');

        } else {
            request['coupons'] = '';
        }
    }
    */

    request['reservation_ref'] = reservation_ref || '';

    /* detect language */
    var lang = 'en';
    const languages = ['en', 'nl', 'de'];

    if (window.location.pathname.length >= 3) {
        var pathlang = window.location.pathname.substr(1,2);
        for (var i=0; i<=languages.length; i++) {
            if (pathlang === languages[i]) {
                lang = pathlang;
            }
        }
    }

    if (attendees == null) {
        request['customer'] = {
            firstname:  '',
            lastname:   '',
            email:      '',
            locale:     lang
        };
        request['attendees'] = [];
        request['attendees'].push({
            firstname:  '',
            lastname:   '',
            email:      ''
        });
    } else {
        request['customer'] = {
            firstname:  attendees['firstname_1'] || '',
            lastname:   attendees['lastname_1'] || '',
            email:      attendees['email_1'] || '',
            locale:     lang
        };
        request['attendees'] = [];
        for (i = 1; i <= nr_of_tickets; i++) {
            if (attendees['firstname_' + i] != null &&
                attendees['lastname_' + i] != null &&
                attendees['email_' + i] != null) {
                    request['attendees'].push({
                        firstname:  attendees['firstname_' + i] || '',
                        lastname:   attendees['lastname_' + i] || '',
                        email:      attendees['email_' + i] || ''
                    });
            }
        }
    }

    // calcualte signature
    var $signature_string = '';

    $signature_string += String(request['event_id']);
    $signature_string += String(request['reservation_ref']);
    $signature_string += String(request['number_of_tickets']);

    /*
    if (request['coupons'] != null) {
        $signature_string += String(request['coupons']);
    }
    */

    if (request['coupons'] != null) {
        for (i=0; i < request['coupons'].length; i++) {
            $signature_string += String(request['coupons'][i]);
        }
    }

    if (request['customer'] != null) {
        $signature_string += String(request['customer']['firstname']);
        $signature_string += String(request['customer']['lastname']);
        $signature_string += String(request['customer']['email']);
    }

    if (request['attendees'] != null) {
        for (i=0; i < request['attendees'].length; i++) {
            $signature_string += String(request['attendees'][i]['firstname']);
            $signature_string += String(request['attendees'][i]['lastname']);
            $signature_string += String(request['attendees'][i]['email']);
        }
    }
    $signature_string += 'PaintFestIsBest';
    request['signature'] = md5($signature_string);

    // delete entry
    // convert to json string and post it.
    var request_str = JSON.stringify(request);

    //console.log(request_str);

    return (dispatch) => {
        //fetch( process.env.REACT_APP_API_BASE + '/api/event/reserve', {
        fetch( 'https://api.paintfest.com/api/event/reserve', {
                method: 'post',
                body: request_str
            }).then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                //dispatch(eventListIsLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(updateReservationSuccess(data)));
            //.catch(() => dispatch(eventListHasErrored(true)));
    };
};

export function updateReservationSuccess(data) {
    //console.log( "updateReservationSuccess: event retrieved: ", JSON.stringify(data) );

    return function(dispatch) {
       dispatch({
            type: 'RESERVATION_RESPONSE',
            payload: data
       })
       dispatch({
           type: 'INVALID_COUPONCODE',
           payload: '',
       })
    }

}
