import path from 'path';
import {combineReducers} from 'redux';
import { eventList, eventListHasErrored, eventListIsLoading, eventListRequested } from './reducer-events';
import ActiveEventReducer from './reducer-active-event';
import EventForCart from './reducer-event-for-cart';
import Reservation from './reducer-reservation';
import Attendees from './reducer-attendees';
import NumberOfTickets from './reducer-nroftickets';
import Coupons from './reducer-coupons';
import InvalidCoupon from './reducer-invalid-coupon';
import { localeReducer } from 'react-localize-redux';
import SearchLocation from './reducer-searchlocation';
import ConfirmationEmailSent from './reducer-resendconfirmationemail';

/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 * */

const allReducers = combineReducers({
    // translations
    locale:                     localeReducer,

    // project related
    eventlist:                  eventList,
    activeEvent:                ActiveEventReducer,
    events_has_errored:         eventListHasErrored,
    events_are_loading:         eventListIsLoading,
    events_requested:           eventListRequested,
    event_for_cart:             EventForCart,
    reservation:                Reservation,
    attendees:                  Attendees,
    nr_of_tickets:              NumberOfTickets,
    coupons:                    Coupons,
    invalid_couponcode:         InvalidCoupon,
    search_location:            SearchLocation,
    
    confirmation_email_sent:    ConfirmationEmailSent
});

export default allReducers
