import React from 'react';
import Searchbar from '../containers/searchbar';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class Home extends React.Component {
    render() {
        /* make shortcut */
        var tr = this.props.translate;
        return (
              <div className="home">
              <section className="search search-home">
              <div className="container">
                <div className="row">
                  <h1>Paint. Drink. Party.<sup>&reg;</sup></h1>
                  <Searchbar onMainPage="yes" />
                </div>
              </div>
            </section>
              <section className="main-content columns">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-md-offset-2 text-center text-block usps">
                    <img alt="" src="/images/icon-paint.png"/>
                    <h3>{ tr('home_color') }</h3>
                    <p>{ tr('home_be_creative') }</p>
                  </div>
                  <div className="col-md-4 text-center text-block usps">
                    <img alt="" src="/images/icon-drink.png"/>
                    <h3>{ tr('home_cheers') }</h3>
                    <p>{ tr('home_share_the_fun') }</p>
                  </div>
                  <div className="clear clearfix clr"></div>
                  <div className="col-md-4 col-md-offset-2 text-center text-block usps">
                    <img alt="" src="/images/icon-party.png"/>
                    <h3>{ tr('home_close') }</h3>
                    <p>{ tr('home_parties_are_held_par1') }<br />{ tr('home_parties_are_held_par2') }</p>
                  </div>
                  <div className="col-md-4 text-center text-block usps">
                    <img alt="" src="/images/icon-ticket.png"/>
                    <h3>{ tr('home_choose') }</h3>
                    <p>{ tr('home_get_your_ticket') }</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="footer-image">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-md-offset-3 text-center">
                  </div>
                </div>
              </div>
            </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(Home);
