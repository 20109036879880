import React from 'react';
import ReservationReminder from '../containers/reservationreminder';
import PFCookie from '../containers/pfcookie';

//import Beforeunload from 'react-beforeunload';
                    //<p><span style="color:#ec008c; font-size: 150%;">K9H0SKGB9TM9Z67LJQ</span></p>

/* history */
class ThankYou extends React.Component {


//    componentDidMount() {
//      //window.addEventListener("popstate", this.handleBeforeunload);
//        var stateObj = {};
//        window.history.pushState(stateObj, "events", window.location.href);
//    }

    render() {
        var reservation_ref = -1;
        var params = this.props.location.search.split("&");
        for (var i=0; i < params.length; i++) {
            var kv = params[i].split("=");
            if (kv[0] === "referenceID") {
                reservation_ref = kv[1];
            }
        }

        return (
            <div>
                <PFCookie event_id={this.props.match.params.event_id} />
                <section className="main main-title">
            		<div className="container">
            			<div className="row">
            				<div className="col-md-12 text-center">
            					<h1>Thank you</h1>
            				</div>
            			</div>
            		</div>
            	</section>

                <section className="cart-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 text-center">
                                <ReservationReminder reservation_ref={reservation_ref} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default ThankYou;
