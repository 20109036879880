import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';


class Header extends React.Component {
    render() {
        /* make shortcut */
        var tr = this.props.translate;

        var support_tab = '';
        if (window.location.pathname.indexOf("about-us") >= 0) {
            support_tab = 'aboutus';
        } else if (window.location.pathname.indexOf("events") >= 0) {
            support_tab = 'events';
        } else if (window.location.pathname.indexOf("support") >= 0) {
            support_tab = 'support';
        }

        var rel_url = window.location.pathname.substr(4);
        var sel_lang = this.props.lang.toUpperCase();

        return (
            <section className="main-menu">
                <div className="row">
                    <div className="col-md-12">
                        <div className="toggle-container">
                            <div className="bar top-bar"></div>
                            <div className="bar middle-bar"></div>
                            <div className="bar bottom-bar"></div>
                        </div>
                        <a href={"/" + this.props.lang + "/"}><img alt="" className="logo" src="/images/logo-paintfest.png" /></a>
                        <ul className="language">
                            <li className="dropdown">
                                {sel_lang} <i className="fa fa-angle-down"></i>
                                <ul className="language-list">
                                    {sel_lang !== 'EN' && <li> <a href={"/en/" + rel_url} title="English">English</a> </li> }
                                    {sel_lang !== 'NL' && <li> <a href={"/nl/" + rel_url} title="Nederlands">Nederlands</a> </li> }
                                </ul>
                            </li>
                        </ul>
                        <ul className="mobile">
                            <li className={support_tab === 'events' ? 'active' : ''} >
                                <a href={"/" + this.props.lang + "/events" } title={ tr('header_events')}>{ tr('header_events') }</a>
                            </li>

                            <li className={support_tab === 'companyevents' ? 'active' : ''} >
                                <a href="/companyevents.html" title={ tr('header_company_events')}>{ tr('header_company_events') }</a>
                            </li>

                            <li className={support_tab === 'aboutus' ? 'active' : ''} >
                                <a href={"/" + this.props.lang + "/about-us"} title={ tr('header_about_us') }>{ tr('header_about_us') }</a>
                            </li>
                            <li className={support_tab === 'support' ? 'active' : ''} >
                                <a href={"/" + this.props.lang + "/support" } title={ tr('header_support') }>{ tr('header_support') }</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}


function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(Header);
