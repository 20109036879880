import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectEvent,eventListFetchData} from '../actions/index'
import {loadLocation} from '../actions/determine-location'

import EventDate from '../containers/eventdate';
import Rating from '../containers/rating';
import { getTranslate } from 'react-localize-redux';

require('dotenv').config();

/* global $ */
class EventList extends Component {
    constructor() {
        super();

        this.state = {
            eventlist: [ ],
            hasErrored: false,
            isLoading: false,
            images: {}
        };
    }

    onLoad(e) {
        var images = Object.assign({}, this.state.images);

        images[e.target.src] = true;

        this.setState(
            {'images': images}
        );

        // get all images from event
        var event_images = {};
        var all_loaded = true;
        for (var i=0; i< this.props.eventlist.events.length; i++) {
            e = this.props.eventlist.events[i];
            event_images['https://storage.googleapis.com/paintfest/'+e.painting_image] = false;

            if (images['https://storage.googleapis.com/paintfest/'+e.painting_image]) {
                event_images['https://storage.googleapis.com/paintfest/'+e.painting_image] = true;
            } else {
                all_loaded = false;
            }
        }
        if (all_loaded) {
            this.applyMasonry();
        }
    }

    componentDidMount() {
        /* try to get query from window.location */
        if (window.location.search !== 'doethetaltijd') {
            var nrguests = 1;
            var location = {};
            var params = window.location.search.split("&");
            for (var i=0; i < params.length; i++) {
                var kv = params[i].split("=");
                if (kv[0] === "tickets") {
                    nrguests = kv[1];
                }
                if (kv[0] === "address") {
                    location.address = decodeURIComponent(kv[1]);
                }
                if (kv[0] === "lat") {
                    location.lat = kv[1];
                }
                if (kv[0] === "lng") {
                    location.lng = kv[1];
                }
            }
            //this.props.fetchData(process.env.REACT_APP_API_BASE + '/api/event/get_events_for_location' + window.location.search, nrguests, location);
            //this.props.fetchData('https://api.paintfest.com/api/event/get_events_for_location' + window.location.search, nrguests, location);
            this.props.fetchData('https://api.paintfest.com/api/event/get_events_for_location?lat=52.356883319055&lng=4.9102483042712&dst=2000', nrguests, location);

            return;
        }

        /* get location and trigger update of event list */
        this.props.loadLocation(true);
    }

    renderTicketsAvailable(available) {
            if (available > 0) {
                return (
    				<div className="actions">
    					<div className="tickets-left">{available} { this.props.translate('event_details_tickets_left') }</div>
    					<div className="buy open-details">{ this.props.translate('event_list_buy') }</div>
    				</div>
                );
            } else {
                return (
    				<div className="actions">
    					<div className="buy">{ this.props.translate('event_details_sold_out') } </div>
    				</div>
                );
            }
    }

    renderMonths() {
        if (typeof(this.props.eventlist.events) === 'undefined') {
            return '';
        }

        if (this.props.eventlist.events.length === 0) {
            return '';
        }

        var month_events = [];
        month_events[0] = [];

        //var cur_month   = '';
        //var i_month     = -1;
        var i_month     = 0;

        for (var i=0; i<this.props.eventlist.events.length; i++) {
            var event = this.props.eventlist.events[i];

            // var m = event.event_startdatetime.substr(5, 2);

            /*
            if (m !== cur_month) {
                cur_month = m;
                i_month++;
                month_events[i_month] = [];
            }
            */

            month_events[i_month].push(event);
        }


        return month_events.map((month) => {
            /*
            var m_names = {
                "01":   this.props.translate('january'),
                "02":   this.props.translate('february'),
                "03":   this.props.translate('march'),
                "04":   this.props.translate('april'),
                "05":   this.props.translate('may'),
                "06":   this.props.translate('june'),
                "07":   this.props.translate('july'),
                "08":   this.props.translate('august'),
                "09":   this.props.translate('september'),
                "10":   this.props.translate('october'),
                "11":   this.props.translate('november'),
                "12":   this.props.translate('december')
            };
            var monthname = m_names[month[0].event_startdatetime.substr(5,2)];
            return (
                <div key={monthname}>
                    <section className="search-results month">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 handwritten-line">
                                    <p>{monthname}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="search-results">
                        <div className="container">
                            <div className="row">
                              <div className="grid">
                                {this.renderMonth(month)}
                              </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
            */

            return (
                    <section key='sr' className="search-results">
                        <div className="container">
                            <div className="row">
                              <div className="grid">
                                {this.renderMonth(month)}
                              </div>
                            </div>
                        </div>
                    </section>
            );
        });
    }

    renderMonth(events) {

        return events.map((event) => {

          var hot_or_not = (event.event_tickets_available < 10) ? 'item hot' : 'item';

          var tmpStyle = { background: "url( 'https://storage.googleapis.com/paintfest/" + event.artist_thumbnail + "')" };
            return (
          <div key={event.event_id} className="col-md-3 col-sm-6 col-xs-6 tile">
    				<div className={hot_or_not} onClick={() => this.props.selectEvent(event)} key={event.event_id} >
    					<div className="image">
    						<img alt="" src={"https://storage.googleapis.com/paintfest/" + event.painting_image} onLoad={(e) => this.onLoad(e) }/>
    						<EventDate datetime={event.event_startdatetime} />
                            <div className="selling-fast"><i className="fa fa-flash"></i>{ this.props.translate('hot') }</div>
    					</div>
    					<div className="details">
    						<h3 className="title"> {event.event_name} </h3>
    						<p className="location">{event.venue_name} {this.props.translate('in')} <strong>{event.venue_city}</strong></p>
    					</div>
    					<div className="artist">
    						<div className="avatar" style={tmpStyle} ></div>
    						<h4 className="name">{event.artist_displayname}</h4>
                            <Rating avg={event.artist_rating_avg} count={event.artist_rating_count}/>
    					</div>
                        {this.renderTicketsAvailable(event.event_tickets_available)}
    				</div>
    			</div>
            );
        });
    }


    applyMasonry() {
        // wait for a paint to apply masonry
        window.requestAnimationFrame(function() {
            $('.grid').masonry({
              itemSelector: '.tile',
            });
        });
    }

    componentDidUpdate() {
        this.applyMasonry()
    }

    render() {
        if (this.props.hasErrored) {
            return <p>{ this.props.translate('event_list_error') }</p>
        }

        if (this.props.isLoading) {
            return <p></p>
        }

        var city = (this.props.search_location === null) ? '' : this.props.search_location.city;

        return (
          <div>
                <section className="search-results main-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>{ this.props.translate('events_near') } <span>{city}</span></h1>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderMonths()}
          </div>
        );
    }
};

function mapStateToProps(state) {
   return {
        eventlist:          state.eventlist,
        hasErrored:         state.events_has_errored,
        isLoading:          state.events_are_loading,
        search_location:    state.search_location,
        config:             state.config,
        translate:          getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        fetchData:      (url, nr_guests, l) => dispatch(eventListFetchData(url, nr_guests, l)),
        selectEvent:    (event) => dispatch(selectEvent(event)),
        loadLocation:   (go_fetch) => dispatch(loadLocation(go_fetch)),
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(EventList);
