import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class Footer extends React.Component {
    render() {
        /* make shortcut */
        var tr = this.props.translate;

        return (
            <section className="footer">
                <div className="clr"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tag-line">
                            <span className="tagline handwriting"><span>S</span>ip&nbsp;&nbsp;and&nbsp;&nbsp;paint&nbsp;&nbsp;the&nbsp;&nbsp;night&nbsp;&nbsp;away&nbsp;!</span>
                        </div>
                        <div className="col-md-3 col-sm-4 hidden-xs hidden-sm">
                            <img alt="" className="logo" src="/images/logo-paintfest.png" />
                        </div>
                        <div className="col-md-3 col-sm-4 col-sm-12">
                            <p><strong>{ tr('footer_about') }</strong></p>
                            <ul>
                                <li>
                                    <a href={"/" + this.props.lang + "/about-us" } >{ tr('footer_about_us') }</a>
                                </li>
                                <li>
                                    <a href={"/" + this.props.lang + "/press" } >{ tr('footer_press') }</a>
                                </li>
                                <li>
                                    <a href={"/" + this.props.lang + "/terms-conditions" } >{ tr('footer_terms_conditions') }</a>
                                </li>
                                <li>
                                    <a href={"/" + this.props.lang + "/privacy-policy" } >{ tr('footer_privacy') }</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-3 col-sm-4">
                            <p><strong>{ tr('footer_support') }</strong></p>
                            <ul>
                                <li>
                                    <a href={"/" + this.props.lang + "/resend-confirmation-email"}>{ tr('footer_resend_confirmation') }</a>
                                </li>
                                <li>
                                    <a href={"/" + this.props.lang + "/reschedule-my-booking"}>{ tr('footer_reschedule') }</a>
                                </li>
                                <li>
                                    <a href={"/" + this.props.lang + "/support"}>{ tr('footer_help') }</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-9 col-md-offset-3 col-sm-12 copyright">
                            <p>&copy; {(new Date()).getFullYear()} { tr('footer_all_rights') }<br/> 
                            { tr('footer_new1') } <br/>
                            { tr('footer_new2') } <br/>
                            { tr('footer_new3') }<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(Footer);
