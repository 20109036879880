//import 'babel-polyfill';
import path from 'path';

import React from 'react';
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import allReducers from './reducers';
import PaintFest from './components/PaintFest';
import { CookiesProvider } from 'react-cookie';
import { createLogger } from 'redux-logger'

import { initialize } from 'react-localize-redux';
import { addTranslationForLanguage } from 'react-localize-redux';

const logger = createLogger();
const store = createStore(
    allReducers,
    applyMiddleware(thunk, promise, logger)
);

/* detect language */
var lang = 'en';
const languages = ['en', 'nl', 'de'];

if (window.location.pathname.length >= 3) {
    var pathlang = window.location.pathname.substr(1,2);
    for (var i=0; i<=languages.length; i++) {
        if (pathlang === languages[i]) {
            lang = pathlang;
        }
    }
} else {
    const detectedLanguage = (navigator.languages && navigator.languages[0]) ||
                             navigator.language ||
                             navigator.userLanguage;

    const ln = detectedLanguage.substr(0, 2);

    for (i=0; i<=languages.length; i++ ) {
        if (languages[i] === ln) {
            lang = ln;
        }
    }

    // temporarily override
    //window.location = '/' + lang + '/events';
    //window.location = '/nl/events';
    window.location = '/nl/';


    ReactDOM.render(
        <div></div>
    );
}

store.dispatch(initialize([lang]));
const lang_file = require('./lang/' + lang + '.json');
store.dispatch(addTranslationForLanguage(lang_file, lang));
store.dispatch(initialize([lang], { defaultLanguage: lang }));

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <PaintFest lang={lang}/>
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
);
