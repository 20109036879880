import React, {Component, useState} from 'react';
import DatePicker from 'react-datepicker';
import {connect} from 'react-redux';
import moment from 'moment';
import {eventListFetchData,updateEventAddress} from '../actions/index'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { getTranslate } from 'react-localize-redux';
import {loadLocation} from '../actions/determine-location';


require('dotenv').config();

/*global google*/
class Searchbar extends Component {
    constructor(props) {
        super();

        var address     = '';
        var startDate   = new Date();
        var endDate     = new Date();
        endDate.setDate(endDate.getDate()+91);
        var nrGuests    = '';

        console.log(startDate);
        console.log(endDate);

        if (window.location.search !== '') {
            var params = window.location.search.split("&");
            for (var i=0; i < params.length; i++) {
                var kv = params[i].split("=");

                if (kv[0] === "address") {
                    address = decodeURIComponent(kv[1]);
                    props.updateEventAddress(address);
                }

                if (kv[0] === "start") {
                    startDate = moment(kv[1]).toDate();
                }

                if (kv[0] === "end") {
                    endDate = moment(kv[1]).toDate();
                }

                if (kv[0] === "tickets") {
                    nrGuests = kv[1];
                }
            }
        } else {
            props.loadLocation();
        }

        this.state = {
            "address":      address,
            "startDate":    startDate,
            "endDate":      endDate,
            "nrGuests":     nrGuests,
            "hasCleared":   true,
        };
        this.onChange = (address) => this.handleAddressChange(address);
        this.onClick  = (address) => this.handleAddressChange('');
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleNrGuestsChange = this.handleNrGuestsChange.bind(this);
    }

    handleAddressChange(address) {
        if (!this.state.hasCleared) {
            this.props.updateEventAddress('', this.props.search_location);
            this.setState({
                hasCleared: true
            });
            return;
        }

        /*
        if (typeof(address) === 'object') {
            address = address.address;
        }
        */
        this.props.updateEventAddress(address, this.props.search_location);
    }

    searchLocation() {
        var start = this.convertToDate(this.state.startDate);
        var end = this.convertToDate(this.state.endDate);
        var nr_guests = this.state.nrGuests;


        // if coming from main page, forward to events page
        if (typeof(this.props.onMainPage) !== 'undefined') {
            if (this.props.search_location.address == "undefined, undefined") {
                this.props.search_location.address = "Amsterdam, NL";
            }
            if (this.props.search_location.address == "" ) {
                this.props.search_location.address = "Amsterdam, NL";
            }
            console.log("search: " + this.props.search_location.address);
            geocodeByAddress(this.props.search_location.address)
                .then(results => getLatLng(results[0]))
                .then((latLng) => {
                    var lat = latLng['lat'];
                    var lng = latLng['lng'];

                    var url = 'events?'
                            + 'lat=' + lat
                            + '&lng=' + lng
                            + '&dst=2000&start=' + start
                            + '&end=' + end;

                    if (typeof(nr_guests) !== "undefined") {
                        url = url + '&tickets=' + nr_guests;
                    }

                    if (typeof(this.props.search_location.address) !== "undefined") {
                        url = url + '&address=' + this.props.search_location.address;
                    }
                    window.location.href = url;
                }).catch(error => console.error('Error', error) );

        } else {
            if (this.props.search_location.address == "undefined" || !this.props.search_location.address ) {
                this.props.search_location.address = "Amsterdam, NL";
            }
            geocodeByAddress(this.props.search_location.address)
                .then(results => getLatLng(results[0]))
                .then((latLng) => {
                    var lat = latLng['lat'];
                    var lng = latLng['lng'];
                    //var url = process.env.REACT_APP_API_BASE + '/api/event/get_events_for_location?'
                    var url = 'https://api.paintfest.com/api/event/get_events_for_location?'
                            + 'lat=' + lat + '&lng=' + lng + '&dst=100&start=' + start + '&end=' + end;
                    console.log(url);
                    this.props.fetchData(url, nr_guests, { 'lng': lng, 'lat': lat, 'address': this.props.search_location.address});
                }).catch(error => console.error('Error hier', error) );
        }
    }

    convertToDate(millisec) {
        var ed = new Date(millisec);
        var y = ed.getFullYear();
        var m = (ed.getMonth() < 9) ? '0'+(ed.getMonth()+1) : ''+(ed.getMonth()+1);
        var d = (ed.getDate() < 10) ? '0'+ed.getDate() : ''+ed.getDate();
        var dstr = y+m+d;
        //console.log("end: " + dstr);

        return dstr;
    }

    handleStartDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleSelect = address => {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => console.log('Success', latLng))
        .catch(error => console.error('Error', error));
    };

    handleEndDateChange(date) {
        this.setState({
            endDate: date
        });
    }

    handleNrGuestsChange(e) {
        this.setState({
            nrGuests: e.target.value
        });
    }

    handleClick(address) {
        console.log(address.description);
        this.props.search_location.address = address.description;
        geocodeByAddress(address.description)
            .then(results => getLatLng(results[0]))
            .then(latLng => this.setState({address: address.description, lat: latLng.lat, long: latLng.lng}))
            .catch(error => console.error('Error', error));
    }

    renderLocationFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
          <input {...getInputProps({
              placeholder: 'Search...'
          })} />
          <div className="autocomplete-dropdown-container">
            {suggestions.map(suggestion => (
              <div {...getSuggestionItemProps(suggestion)} onClick={(e) => {this.handleClick(suggestion)}}>
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
    );


    renderSearchBar() {
        const cssClasses = {
            input: {
                float: 'left',
                width: '100%',
                lineHeight: '40px',
                fontSize: '18px',
                padding: '20px 10px 20px 50px',
                border: '0'
            },
            autocompleteContainer: {
                position: 'absolute',
                backgroundColor: 'white',
                border: '0px solid #555555',
                width: '100%',
                top: '75px',
                zIndex: '1977'
            },
            googleLogoContainer: {
                display: 'none'
            },
            googleLogoImage: {
                display: 'none'
            }
        };


        const inputProps = {
            value:          (this.props.search_location !== null && 
                             this.props.search_location.address != null &&
                             this.props.search_location.address != "undefined, undefined") ? this.props.search_location.address : 'Amsterdam, NL',
            onChange:       this.onChange,
            onClick:        this.onClick,
        };

        const options = {
            googleLogo: false,
            location: new google.maps.LatLng( 52.2231778, 4.9084966 ),
            radius: 200000,
            types: ['(cities)']
        };


        var tr = this.props.translate;



                            //</PlacesAutocomplete>inputProps={inputProps} options={options} styles={cssClasses} />
                           //inputProps={inputProps} options={options} styles={cssClasses} 
                                //onSelect={this.handleSelect} 
        return (
       		<div className="col-md-10 col-md-offset-1 searchform">
       			<div className="location">
                           <i className="fa fa-map-marker"></i>
                           <PlacesAutocomplete 
                                inputProps={inputProps}
                                styles={cssClasses} 
                                onChange={this.handleAddressChange} 
                                value={this.state.address} >
                            { this.renderLocationFunc }
                           </PlacesAutocomplete>
         			</div>
         			<div className="date">
         				<i className="fa fa-calendar cal"></i>
         				<i className="fa fa-angle-right till"></i>
                       <DatePicker portalId="root-portal" selected={this.state.startDate} onChange={this.handleStartDateChange} dateFormat="dd/MM" />
                       <DatePicker portalId="root-portal" selected={this.state.endDate} onChange={this.handleEndDateChange} dateFormat="dd/MM" />
         			</div>
         			<div className="guests">
         				<i className="fa fa-user"></i>
                       <select name="guests" onChange={this.handleNrGuestsChange} defaultValue={this.state.nrGuests}>
                           <option value="0">{ tr('nr_of_tickets') }</option>
                           <option value="1">1 { tr('ticket') }</option>
                           <option value="2">2 { tr('tickets')}</option>
                           <option value="3">3 { tr('tickets')}</option>
                           <option value="4">4 { tr('tickets')}</option>
                           <option value="5">5 { tr('tickets')}</option>
                           <option value="6">6 { tr('tickets')}</option>
                           <option value="7">7 { tr('tickets')}</option>
                           <option value="8">8 { tr('tickets')}</option>
                           <option value="9">9 { tr('tickets')}</option>
                           <option value="10">10 { tr('tickets')}</option>
                           <option value="11">11 { tr('tickets')}</option>
                           <option value="12">12 { tr('tickets')}</option>
                           <option value="13">13 { tr('tickets')}</option>
                           <option value="14">14 { tr('tickets')}</option>
                           <option value="15">15 { tr('tickets')}</option>
                           <option value="16">16 { tr('tickets')}</option>
                           <option value="17">17 { tr('tickets')}</option>
                           <option value="18">18 { tr('tickets')}</option>
                           <option value="19">19 { tr('tickets')}</option>
                           <option value="20">20+ { tr('tickets')}</option>
                       </select>
           		</div>
                   <input className="button" value={ tr('search') } type="button" id="search_button" onClick={() => this.searchLocation() } />
           	</div>
       );
    }

    render() {

        if (typeof(this.props.onMainPage) === 'undefined') {
            return (
                <section className="search">
            		<div className="container">
            			<div className="row">
                            {this.renderSearchBar()}
                		</div>
                	</div>
                </section>
            );

        } else {
            return this.renderSearchBar()
        }
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
       search_location:     state.search_location
    };
}

function matchDispatchToProps(dispatch) {
    return {
        fetchData:          (url, nr_guests, l) => dispatch(eventListFetchData(url, nr_guests, l)),
        updateEventAddress: (address, location) => dispatch(updateEventAddress(address, location)),
        loadLocation:       ()                  => dispatch(loadLocation()),
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(Searchbar);
