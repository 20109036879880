require('dotenv').config();


/* events selected */
export function requestResendConfirmationEmail(email) {
    //console.log('requestResendConfirmationEmail ', email);
    //var url = process.env.REACT_APP_API_BASE + '/api/event/resend?email=' + email;
    var url = 'https://api.paintfest.com/api/event/resend?email=' + email;

    return (dispatch) => {
        fetch(url).then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(handleResendConfirmationEmail(data)));
    };
};

export function handleResendConfirmationEmail(data) {
    return {
        type: 'CONFIRMATION_EMAIL_SENT',
        payload: true
    }
}
