require('dotenv').config();

/* events selected */
export function loadReservation(reservation_ref, event_id) {
    //console.log('loadReservation called for ', reservation_ref);
    //var url = process.env.REACT_APP_API_BASE + '/api/event/reservation/' + reservation_ref;
    var url = 'https://api.paintfest.com/api/event/reservation/' + reservation_ref;

    return (dispatch) => {
            fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                //dispatch(eventListIsLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(loadReservationSuccess(data, event_id)));
            //.catch(() => dispatch(eventListHasErrored(true)));
    };
};

export function loadReservationSuccess(data, event_id) {
    //console.log( "loadReservationSuccess: event retrieved: ", JSON.stringify(data) );

    if (parseInt(event_id, 10) !== -1 && parseInt(data.event_id, 10) !== parseInt(event_id, 10)) {
        //console.log("loaded:", data.event_id);
        //console.log("given:", event_id);

        return {
            type:       'RESERVATION_NO_MATCH',
            payload:    event_id
        }
    }


    if (parseInt(event_id, 10) !== -1 && data.status === 'paid') {
        //console.log("reservation:", data.reservation_ref, "is already paid");
        return {
            type:       'RESERVATION_NO_MATCH',
            payload:    event_id
        }
    }

    // build attendee_info badsed on information
    var attendee_info = {};

    for (var i = 0; i<data.attendees.length; i++) {
        var idx = i + 1;
        attendee_info['firstname_' + idx] = data.attendees[i]['firstname'];
        attendee_info['lastname_' + idx] = data.attendees[i]['lastname'];
        attendee_info['email_' + idx] = data.attendees[i]['email'];
    }

    // build reservation
    var reservation = {};
    reservation['reservation_ref'] = data.reservation_ref;
    reservation['event_id'] = data.event_id;
    reservation['number_of_tickets'] = data.number_of_tickets;
    reservation['total_price'] = data.total_amount;
    reservation['total_discount'] = data.total_discount;

    var coupons = [];
    if (data.coupons.length > 0) {
        coupons = data.coupons;
    }


    return function(dispatch) {
       dispatch({
           type: 'ATTENDEES_UPDATED',
           payload: attendee_info,
       })
       dispatch({
           type: 'NR_OF_TICKETS_UPDATED',
           payload: data.number_of_tickets,
       })
       dispatch({
           type: 'RESERVATION_RESPONSE',
           payload: reservation,
       })
       dispatch({
           type: 'UPDATED_COUPONCODES',
           payload: coupons
       })
   }
}
