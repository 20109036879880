import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import App from '../components/App';
import Cart from '../components/Cart';
import Home from '../components/Home';
import Press from '../components/Press';
import Jobs from '../components/Jobs';
import ApplyArtist from '../components/ApplyArtist';
import ApplyVenue from '../components/ApplyVenue';
import TermsConditions from '../components/TermsConditions';
import PrivacyPolicy from '../components/PrivacyPolicy';
import ThankYou from '../components/ThankYou';
import PaymentCancelled from '../components/PaymentCancelled';
import ThankYouForm from '../components/ThankYouForm';
import AboutUs from '../components/AboutUs';
import Support from '../components/Support';
import EventPage from '../containers/eventpage';
import PartnerWithUs from '../components/PartnerWithUs';
import HostAPaintFestEvent from '../components/HostAPaintFestEvent';
import BecomeAVenue from '../components/BecomeAVenue';
import BecomeAnEventOrganizer from '../components/BecomeAnEventOrganizer';
import CreativeEntrepreneur from '../components/CreativeEntrepreneur';
import ResendConfirmationEmail from '../containers/resendconfirmationemail';
import RescheduleMyBooking from '../components/RescheduleMyBooking';

import { BrowserRouter as Router, Route } from 'react-router-dom';

class PaintFest extends React.Component {
    render() {
        return (
            <div>
                <Header lang={this.props.lang} />
                <div>
                    <Router>
                        <Route exact={true} path="/:lang/press"                       component={Press}                   />
                        <Route exact={true} path="/:lang/jobs"                        component={Jobs}                    />
                        <Route exact={true} path="/:lang/apply"                       component={ApplyArtist}             />
                        <Route exact={true} path="/:lang/apply-venue"                 component={ApplyVenue}              />
                        <Route exact={true} path="/:lang/privacy-policy"              component={PrivacyPolicy}           />
                        <Route exact={true} path="/:lang/terms-conditions"            component={TermsConditions}         />
                        <Route exact={true} path="/:lang/about-us"                    component={AboutUs}                 />
                        <Route exact={true} path="/:lang/support"                     component={Support}                 />
                        <Route exact={true} path="/:lang/event/:event_id*"           component={EventPage}               />
                        <Route exact={true} path="/:lang/partner-with-us"             component={PartnerWithUs}           />
                        <Route exact={true} path="/:lang/venue"                       component={BecomeAVenue}            />
                        <Route exact={true} path="/:lang/host-a-paintfest-event"      component={HostAPaintFestEvent}     />
                        <Route exact={true} path="/:lang/become-an-event-organizer"   component={BecomeAnEventOrganizer}  />
                        <Route exact={true} path="/:lang/resend-confirmation-email"   component={ResendConfirmationEmail} />
                        <Route exact={true} path="/:lang/reschedule-my-booking"       component={RescheduleMyBooking}     />
                        <Route exact={true} path="/:lang/creative-entrepreneur"       component={CreativeEntrepreneur}    />
                        <Route path="/:lang/cart/:event_id"                           component={Cart}                    />
                        <Route path="/:lang/events"                                   component={App}                     />
                        <Route exact={true} path="/:lang/thankyou"                    component={ThankYou}                />
                        <Route exact={true} path="/:lang/paymentcancelled"            component={PaymentCancelled}        />
                        <Route exact={true} path="/:lang/form-submitted"              component={ThankYouForm}            />
                        <Route exact={true} path="/:lang/"                            component={Home}                    />

                        <Route exact={true} path="/getonboard" render={() => (
                           <Redirect to={ this.props.lang + "/jobs"} />
                        )}/>
                    </Router>
                </div>
                <Footer lang={this.props.lang}/>
            </div>
        );
    }
}

export default PaintFest;
