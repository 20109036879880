import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadReservation} from '../actions/load-reservation';
import {selectEventForCart} from '../actions/index'
import { getTranslate } from 'react-localize-redux';

class ReservationReminder extends Component {
    constructor(props) {
        super();
    }

    componentDidMount() {
        this.props.loadReservation(this.props.reservation_ref, -1);
    }

    render() {
        var reservation = this.props.reservation;
        var event = this.props.event;

        if (reservation == null) {
            return (<div></div>);
        }

        if (event === null) {
            this.props.selectEventForCart(reservation.event_id);
            return (<div></div>);
        }

        var tickets = this.props.translate('ticket');
        if (reservation.number_of_tickets > 1) {
            tickets = this.props.translate('tickets');
        }
        return (
            <div>
                <p>{ this.props.translate('reservation_p1') } { reservation.number_of_tickets } {tickets} { this.props.translate('reservation_p2') } {event.event_name}!</p>
                <p>{ this.props.translate('reservation_p3a') }</p>
                <p>{ this.props.translate('reservation_p3b') }</p>
                <p><span>{reservation.reservation_ref}</span></p>
                <p><strong>{ this.props.translate('reservation_p4') }</strong></p>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       reservation:         state.reservation,
       event:               state.event_for_cart,
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        loadReservation:    (reservation_ref, event_id)     => dispatch(loadReservation(reservation_ref, event_id)),
        selectEventForCart: (event_id)                      => dispatch(selectEventForCart(event_id))

    };
};


export default connect(mapStateToProps, matchDispatchToProps)(ReservationReminder);
