import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { requestResendConfirmationEmail } from '../actions/resenconfirmationemail';

class ResendConfirmationEmail extends React.Component {

    constructor(props) {
        super();

        //this.handleChange = this.handleChange.bind(this);
        this.state = { email: '' }
    }


    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    handleResendConfirmationEmail() {
        //console.log("pushed the button");
        this.props.requestResendConfirmationEmail(this.state.email);
    }

    render () {
        /* make shortcut */
        var tr = this.props.translate;

        return (
            <div className="support page">
              <section className="main main-title">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 text-center">
                        <h1>{ tr('resend_title') }</h1>
                        <p className="intro">{ tr('resend_par') }</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content">
                <div className="container">
                  <div className="row">
                    { this.props.confirmation_email_sent &&
                        <div className="col-md-6 col-md-offset-3 text-block">
                          <p><strong>{ tr('resend_linked') }</strong></p>
                        </div>
                    }
                    <div className="col-md-6 col-md-offset-3">
                      <div className="contact-form text-center resend">
                        <input type="text" id="email" placeholder="Enter your email" onChange={(e) => this.handleEmailChange(e) } />
                        <input type="button" className="button ghost" value="Resend email" onClick={() => this.handleResendConfirmationEmail() } />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:                   getTranslate(state.locale),
       confirmation_email_sent:     state.confirmation_email_sent
    };
}
function matchDispatchToProps(dispatch) {
    return {
        requestResendConfirmationEmail:     (email) => dispatch(requestResendConfirmationEmail(email)),
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(ResendConfirmationEmail);
