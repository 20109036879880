import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class Jobs extends React.Component {
    render() {
        /* make shortcut */
        var tr = this.props.translate;

        return (
          <div className="apply page press">
            <section className="main main-title">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1>{ tr('footer_jobs') }</h1>
                  </div>
                </div>
              </div>
            </section>
            <section className="main-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <ul>
                      <li>
                        <a href="/">
                          <h3>Event Organizer</h3>
                          <p>Anywhere</p>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <h3>Entertainer/Painting instructor</h3>
                          <p>Anywhere</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
    };
};

export default connect(mapStateToProps, matchDispatchToProps)(Jobs);
