import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

class Support extends React.Component {

    render () {
        /* make shortcut */
        var tr = this.props.translate;

        return (
            <div className="support page">
            <section className="main main-title">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h1>{ tr('support_header') }</h1>
                      <h2 className="handwriting">{ tr('support_questions') }</h2>
                    </div>
                  </div>
                </div>
              </section>

              <section className="main-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-block">
                      <h3>{ tr('support_q1') }</h3>
                      <p>{ tr('support_a1') }</p>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                      <h3>{ tr('support_q2') }</h3>
                      <p>{ tr('support_a2') }</p>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                      <h3>{ tr('support_q3') }</h3>
                      <p>{ tr('support_a3') }</p>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                      <h3>{ tr('support_q4') }</h3>
                      <p>{ tr('support_a4') }</p>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                      <h3>{ tr('support_q5') }</h3>
                      <p>{ tr('support_a5') }</p>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                      <h3>{ tr('support_q6') }</h3>
                      <p>{ tr('support_a6') }</p>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                      <h3>{ tr('support_q7') }</h3>
                      <p>{ tr('support_a7') }</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:           getTranslate(state.locale),
    };
}
function matchDispatchToProps(dispatch) {
    return {
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(Support);
