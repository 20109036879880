export function eventListHasErrored(state = false, action) {
    switch (action.type) {
        case 'EVENTLIST_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function eventListRequested(state = false, action) {
    switch (action.type) {
        case 'EVENTLIST_REQUESTED':
            return action.eventListRequested;
        default:
            return state;
    }
}


export function eventListIsLoading(state = false, action) {
    switch (action.type) {
        case 'EVENTLIST_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function eventList(state = [], action) {
    switch (action.type) {
        case 'EVENTLIST_FETCH_DATA_SUCCESS':
            return action.eventlist;

        default:
            return state;
    }
}
