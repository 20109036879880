require('dotenv').config();

/* testing given coupon - calling paintfest API */
export function testCoupon(couponcode, event_id, coupons, total_price) {
    if (couponcode == null) {
        return;
    }

    if (couponcode == '') {
        return;
    }
    
    if (coupons == null) {
        coupons = [];
    }

    //var url = process.env.REACT_APP_API_BASE + '/api/coupon/' + couponcode + '/is_valid?event_id=' + event_id
    var url = 'https://api.paintfest.com/api/coupon/' + couponcode + '/is_valid?event_id=' + event_id
    return (dispatch) => {
            fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                //dispatch(eventListIsLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((data) => dispatch(couponTestResult(data, coupons, total_price)));
            //.catch(() => dispatch(eventListHasErrored(true)));
    };
}

/* process coupon test result */
export function couponTestResult(data, coupons, total_price) {
    if (data.result !== 'OK') {
        return {
            type: 'INVALID_COUPONCODE',
            payload: data.couponcode
        }
    }

    /* test amount */
    if (data.amount !== null) {
        if (data.amount > total_price) {
            return function(dispatch) {
               dispatch({
                   type: 'INVALID_COUPONCODE',
                   payload: data.couponcode,
               })
            }
        }
    }

    /* coupons need to be copied - whatever is returned is immutable */
    var new_coupons = coupons.slice();
    new_coupons.push(data.couponcode);

    return function(dispatch) {
       dispatch({
           type: 'UPDATED_COUPONCODES',
           payload: new_coupons,
       })
       dispatch({
           type: 'INVALID_COUPONCODE',
           payload: '',
       })
    }
};

/* simply set coupons */
export function setCoupons(coupons) {
    return function(dispatch) {
       dispatch({
           type: 'UPDATED_COUPONCODES',
           payload: coupons,
       })
       dispatch({
           type: 'INVALID_COUPONCODE',
           payload: '',
       })
    }
}
