import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setCoupons, testCoupon} from '../actions/coupons'
import TagsInput from 'react-tagsinput'
import {updateReservation} from '../actions/update-reservation';
import { getTranslate } from 'react-localize-redux';


/* global $ */
class Coupons extends Component {
    constructor(props) {
        super();
        this.state = {coupons: props.coupons }
        this.handleChange = this.handleChange.bind(this);
        this.addNewCoupon = this.addNewCoupon.bind(this);
    }

    handleChange(coupons) {
        this.props.setCoupons(coupons);
    }

    allowOnlyChars(e) {
        const re = /[0-9a-zA-Z]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }

        if (e.key === 'Enter') {
            this.addNewCoupon();
        }
    }

    filterChars(e) {
        const re = /[^0-9a-zA-Z]+/g;
        var newval = e.target.value.replace(re, '');
        e.target.value = newval;
    }

    addNewCoupon() {
        var coupon = $("#addCoupon").val();
        if (coupon != "" ) {
            $("#addCoupon").val("");
            this.props.testCoupon(coupon, this.props.event.event_id, this.props.coupons);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.reservation !== null) {
            if ( nextProps !== null && nextProps.event !== null && JSON.stringify(this.props.coupons) !== JSON.stringify(nextProps.coupons) ) {
                this.props.updateReservation(
                    nextProps.event.event_id, nextProps.reservation.reservation_ref,
                    nextProps.nr_of_tickets, nextProps.attendees, nextProps.coupons
                );
            }
        }
    }

    renderInvalidCoupon() {
        if (this.props.invalid_couponcode && this.props.invalid_couponcode !== '') {
            return (<p><strong>{this.props.invalid_couponcode}</strong> { this.props.translate('coupon_invalid') }</p>)
        } else {
            return (<p></p>)
        }
    }

    render() {

        var props = {
            placeholder: this.props.translate('coupon_enter_placeholder')
        };
        var keys = [13];
        var coupons = (this.props.coupons === null) ? [] : this.props.coupons;
        var nr_of_tickets = (this.props.nr_of_tickets !== null ) ? this.props.nr_of_tickets : 1;

        //var more_coupons_allowed = (coupons.length < nr_of_tickets) ? true : false;
        var more_coupons_allowed = (coupons.length > 0)? false: true;

        if (more_coupons_allowed) {
            return (
                <div className="ticket coupon">
                    <div className="col-md-12 nested">
                        <p className="text-label uppercase">
                            { this.props.translate('coupon') }
                        </p>
                    </div>

                    <div className="col-md-8 nested coupon-input">
                        <input defaultValue='' type="text" name="addCoupon" id="addCoupon" onChange={(e) => this.filterChars(e)} onKeyPress={(e) => this.allowOnlyChars(e)}/>
                        <TagsInput addKeys={keys} inputProps={props} value={coupons} onChange={this.handleChange} />
                        {this.renderInvalidCoupon()}

                        <p className="multiple_coupons">{ this.props.translate('coupon_multiple') }</p>
                    </div>
                    <div className="col-md-4 nested total">
                        <button onClick={(e) => this.addNewCoupon(e)} className="button coupon">{ this.props.translate('coupon_apply') }</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="ticket coupon">
                    <div className="col-md-12 nested">
                        <p className="text-label uppercase">
                            { this.props.translate('coupon') }
                        </p>
                    </div>
                    <div className="col-md-8 nested">
                        <TagsInput addKeys={keys} inputProps={props} value={coupons} onChange={this.handleChange} />
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
   return {
       event:                   state.event_for_cart,
       invalid_couponcode:      state.invalid_couponcode,
       attendees:               state.attendees,
       nr_of_tickets:           state.nr_of_tickets,
       reservation:             state.reservation,
       coupons:                 state.coupons,
       translate:               getTranslate(state.locale),
    };
}

function matchDispatchToProps(dispatch) {
    return {
        testCoupon:          (nc, e, c)         => dispatch(testCoupon(nc, e, c)),
        setCoupons:          (c)                => dispatch(setCoupons(c)),
        updateReservation:  (e, r, n, a, c)     => dispatch(updateReservation(e, r, n, a, c)),
     };
};
export default connect(mapStateToProps, matchDispatchToProps)(Coupons);
