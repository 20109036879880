import React, {Component} from 'react';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import {sprintf} from 'sprintf-js';


class EventDate extends Component {

    renderDate(datetime) {
        var monthNames = [
            this.props.translate('jan'),
            this.props.translate('feb'),
            this.props.translate('mar'),
            this.props.translate('apr'),
            this.props.translate('may'),
            this.props.translate('jun'),
            this.props.translate('jul'),
            this.props.translate('aug'),
            this.props.translate('sep'),
            this.props.translate('oct'),
            this.props.translate('nov'),
            this.props.translate('dec')
        ];
        var parts = datetime.split('-');
        var m = parseInt(parts[1], 10) -1;
        var day = parseInt(parts[2].substr(0,2), 10);
        return monthNames[m] + ' ' + day;
    }

    renderTime(datetime) {
        var hours =   parseInt(datetime.substr(11,2), 10);
        var minutes = parseInt(datetime.substr(14,2), 10)

        var new_time = hours + ":" + sprintf("%02d", minutes);
        return new_time;
    }

    render() {
        return (
            <div className="date">
                <strong>{this.renderDate(this.props.datetime)}</strong> <br/>
                {this.renderTime(this.props.datetime)}
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {
       translate:          getTranslate(state.locale),
   };
}

function matchDispatchToProps(dispatch) {
    return { };
};

export default connect(mapStateToProps, matchDispatchToProps)(EventDate);
